@import "../../widgits/css/general.scss";
main{
    @extend %main;
}
.main_fixed{
    margin-top: 90px
}
.userHtml{
    padding: 0 calcFluid(8, 200, 320, 1920) !important;
    @extend %d-col;
    h2{font-size: calcFluid(14, 27, 320, 1920);}
    .orders__info {
        width: 100%;
        @extend %d-col;
        .input__price,
        .input__delivery,
        .input__discount {
            @extend %d-row;
            margin: 10px 0;
            font-size: calcFluid(18, 22, 320, 1920);
            font-family: 'inter-bold';
            p {margin: 0;}
            #price {
                margin: 0 0 0 auto;
                font-size: calcFluid(23, 30, 320, 1920);
                font-family: 'inter-bold';
            }  
            span {
                margin: 0 0 0 auto !important;
                font-size: calcFluid(13, 20, 320, 1920);
                font-family: 'inter-bold';
            }              
        }
       
        .input__delivery {
            margin: 10px 0;
            align-items: center;
        }
        .input__discount{
           margin:  0 0 10px 0;
           font-size: calcFluid(16, 20, 320, 1920);
            #discount {
                font-size: calcFluid(18, 22, 320, 1920);
                color: #f91155;
            }
        }
        .orders__track{
            border-radius: 4px;
            box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.20);
            @extend %d-col;
            padding: calcFluid(10, 18, 320, 1920) calcFluid(10, 18, 320, 1920) calcFluid(20, 30, 320, 1920) calcFluid(10, 18, 320, 1920) ;
            .orders__trackInfo{
                @extend %d-row;
                justify-content: center;
                .orders__trackInfoCol, 
                .orders__trackInfoCol1{
                    position: relative;
                    margin: 0 auto;
                    @extend %d-col;
                    p{  
                        text-align: center;
                        margin: 0;
                        font-family: 'inter-bold';
                        font-size: calcFluid(12, 20, 320, 1920);
                    }
                    .date{
                        margin: 5px 0 calcFluid(5, 25, 320, 1920) 0;
                           font-family: 'inter-regular';
                        color: #58585e;
                    }
                    .borderRadius{
                        z-index: 100;
                        border-radius: 50%;
                        background: gray;
                        width: calcFluid(30, 42, 320, 1920);
                        height: calcFluid(30, 42, 320, 1920);
                        margin: 0 auto;
                        top: calcFluid(-10, -20, 320, 1920);
                        img{
                            margin: calcFluid(6, 8, 320, 1920);
                            width: 60%;
                        }
                    }
                    .active{
                        background: $gradient;
                    }
                    .border{
                        position: absolute;
                        border: 2px solid gray;
                        width: calcFluid(100, 330, 320, 1920);
                        top: calcFluid(55, 100, 320, 1920);
                        left: calcFluid(30, 70, 320, 1920);
                    }
                    
                    .borderActive{
                        border: 2px solid gray !important;
                        border-image-source: linear-gradient(90deg, #5134c4, #773cbc, #b856d4) !important;
                        border-image-slice: 1 !important;
                        background: linear-gradient(90deg, #5134c4, #773cbc, #b856d4);
                        -webkit-background-clip: text;
                        background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
                .orders__trackInfoCol1{
                    .border{
                        width: calcFluid(80, 300, 320, 1920);
                    }
                }
            }
        }
        .writeToShop{
            @extend %more;
            margin:15px calcFluid(0, 220, 320, 1920) 0 calcFluid(0, 220, 320, 1920);
        }
    }
    
    .userContent{
        box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.25);
        @extend %d-row;
        padding: 10px;
        &__photo{
            width: 30%;
            border-radius: calcFluid(20, 71, 320, 1920);
            background: #D9D9D9;
            display: flex;
            flex-direction: row;
            img{
                margin: auto;
                max-width: 100%;
                max-height: 100%;
            }
        }
        .userContent__col{
            width: 70%;
            @extend %d-col;
            margin: 0 0 auto 20px;
            .userContent__name{
                font-size: calcFluid(16, 25, 320, 1920);
                font-family: 'inter-bold';
            }
            p{
                font-size: calcFluid(12, 19, 320, 1920);
            }
            .exit{
                @extend %more;
                margin: auto  auto 0 0;
            }
        }
    }
    .orders{
        @extend %d-col;
        h1{
            font-size: calcFluid(18, 30, 320, 1920);
        }
        .order__clear{
            text-align: center;
            margin: 100px 0;
            h2, p{
                text-align: center;
             }
             h2{
                 font-size: calcFluid(20, 30, 320, 1920);
             }
             p{
                 font-size: calcFluid(14, 18, 320, 1920);
             }
        }
        .exit{
            width: auto;
            @extend %more;
            margin: 10px 0 0 0;
        }
    }
    .bag__block {
        margin: 5px 0;
        @extend %d-row;
        border-radius: 4px;
        box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.20);
        padding: 10px;
        text-decoration: none;
        .radius__user {
            width: 35%;
            padding: calcFluid(4, 5, 320, 1920) calcFluid(6, 25, 320, 1920);
            align-items:center ;
            justify-content: center;
            background-color: #ececec;
            border-radius: 6px;
            display: flex;
            img{
                max-width: auto;
                max-height: calcFluid(125, 160, 320, 1920);
                margin: 0;
            }
        }
        .block__content {
            width: 65%;
            position: relative;
            margin: 0 0 0 calcFluid(8, 10, 320, 1920) !important;
            @extend %d-col;
            color: black;
            .rent {
                color: #58585e;
                font-family: 'inter-regular';
                font-size: calcFluid(14, 18, 320, 1920);
                margin: 0 0 15px 0 !important;
                span{
                    color: black;
                    font-weight: 1000;
                }
            }

            .orderNumber {
                text-decoration: none;
                color: black;
                font-size: calcFluid(15, 25, 320, 1920);
                font-family: 'inter-bold';
                margin: calcFluid(5, 10, 320, 1920) 0;
            }
            .track{
                @extend %more;
                font-size: calcFluid(13, 20, 320, 1920) !important;
                padding: calcFluid(5, 10, 320, 1920)  calcFluid(20, 45, 320, 1920) !important;
                margin: auto auto 0 0;
            }
            .active{
              background: gray;
            }

            .block__inputRow{ 
                margin: auto 0 0 0;
                @extend %d-row;
            }

            .block__input{
                @extend %d-col;
                .fd-col{
                    margin: 0;
                    font-family: 'inter-regular';
                    color: #58585e;
                    font-size: calcFluid(12, 16, 320, 1920);
                    span{
                        font-size: calcFluid(12, 20, 320, 1920);
                        color: black;
                    }
                }
                .fd-col:nth-child(1){
                    margin: auto 0 10px 0;
                }
            }
        }
        .block-col {
            display: flex;
            flex-direction: column;
            margin: auto 0 0 auto;

            .block__price,
            .block__discount {
                font-family: 'inter-bold';
                font-size: calcFluid(17, 20, 320, 1920);
                margin: 5px 0 0 auto;
            }

            .block__discount {
                font-family: 'inter-regular';
                font-size: calcFluid(12, 18, 320, 1920);
                text-decoration: line-through;
                color: #58585e;
            }
        }
    }
}
@media(max-width: 730px) {
    .userHtml {
        .userContent{
            border-bottom: 1px solid #D3D3D3;
            display: flex;
            flex-direction: column;
            box-shadow: none;
            .userContent__photo{
                margin: auto;
                width: 150px;
                border-radius: 50%;
                height: 150px;
                img{
                    width: 90px;
                    height: 90px;
                }
            }
            .userContent__col{
                display: flex;
                flex-direction: column;
                margin: 0;
                width: 100%;
                text-align: center;
                align-items: center;
                .userContent__row{
                    .rename{
                        margin: 0 ;
                    }
                }
                p{
                    margin: 5px 0;
                }
                .userContent__email{
                 font-family: 'inter-bold';
                 font-size: calcFluid(17, 25, 320, 1920);
                }
                .userContent__phone{
                    font-size: calcFluid(12, 16, 320, 1920);
                   }
            }
        }
        .orders{
            .bag__block{
                display: flex;
                .track{
                    margin: 0 !important;
                    text-align: center;
                }
            }
        }
    }
}
@media(max-width: 500px) {
    .userHtml{
        .orders__info {
            .orders__track{
                .border{
                    top: calcFluid(55, 58, 320, 500) !important;
                }
            }
        }
    }
}
@media(max-width: 400px) {
    .userHtml{
        .orders__info {
            .orders__track{
                .border{
                    top: calcFluid(68, 70, 320, 400) !important;
                }
            }
        }
    }
}