@import "../../widgits/adminScss/layOut.scss";

.registerPeople{
    padding: calcFluid(70, 230, 320, 1920) calcFluid(8, 400, 320, 1920);
    @extend %d-col;
    h1{
        margin: 0 0 15px 0;
        font-size:calcFluid(20, 30, 320, 1920);
    }
    &-row{
        @extend %d-col;
        input{
            padding: 15px 10px;
            margin: 5px 0;
            outline:none;
            border: none;
            box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
            font-size:calcFluid(13, 20, 320, 1920);
        }
        label{
            font-size:calcFluid(10, 16, 320, 1920);
            margin: 20px 0;
            input[type='checkbox'] {
                margin: auto 10px auto 0;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                width: 1.5em;
                height:1.5em;
                border: 1px solid #adb5bd;
                cursor: pointer;
                vertical-align:bottom;
                &:checked{
                    background: $gradient;
                    appearance: auto;
                }
            }
        }
        .error{
            font-size: calcFluid(12, 18, 320, 1920);
            color: red;
            margin: 10px 0 0 0;
        }
      
        .loading{
           background: rgb(165, 165, 165) !important;
        }
    }
    .select{
        color: rgb(85, 85, 85);
        font-family: "Inter-Regular";
        outline: none;
        border: none;
        padding: 15px 0px;
        font-size:calcFluid(10, 16, 320, 1920);
        box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
        margin: 5px 0;
        cursor: pointer;
        option{
            color: black;
            font-size:calcFluid(10, 16, 320, 1920);
            &:hover{
                background: rgb(165, 165, 165);
                color: white;
            }
        }
    }
   
}

.registerBTN{
    @extend %more;
    margin: 0 !important;
    width: 100%;
    text-align: center;
}
.deleteBtn{
    font-family: "Inter-Regular";
    color: black;
    font-size:calcFluid(15, 20, 320, 1920);
    padding:calcFluid(5, 10, 320, 1920) calcFluid(10, 20, 320, 1920);
    border: 1px solid;
    border-image-source:  $gradient;
    border-image-slice: 1;
    background: white;
    width: 70%;
    transition: 0.3s;
    &:hover {
        background: #19103C;
        color: white;
        border:  1px solid #19103C;
    }
    margin: auto 0 auto 10px;
}