@import "../../widgits/adminScss/layOut.scss";

.activeCard{
    position: relative;
}
.headerOrder{
    @extend %headerOrder;
}
h1{
    font-family: "Inter-Bold";
    font-size:calcFluid(17, 30, 320, 1920) ;
}
.cards{
    display: flex;
    flex-direction: column;
    height: 100%;
    .order__clear{
        margin: auto;
        h2, p{
           text-align: center;
        }
        h2{
            font-size: calcFluid(18, 30, 320, 1920);
        }
        p{
            font-size: calcFluid(15, 18, 320, 1920);
        }
    }
    .bag__block {
        @extend %bag__block;
        .card__img{
            width: 15%;
            display: flex;
            object-fit: cover;
            img{
                margin: auto;
            }
            .block__content .track{
                margin: 10px 0 0 0;
            }
        }
    }
}
.filter__open{
    cursor: pointer;
    display: flex;
    font-size: 20px;
    @extend %d-row;
    margin: auto 0px auto auto;
    img{
        margin: auto 10px;
        width: calcFluid(22, 35, 320, 1920);
    }
}
.background__black{
    position: absolute;
    top: -85px;
    right: -40px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.247);
}
.katalog__filter2{
    overflow: scroll;
    cursor: pointer;
    z-index: 200;
    position: absolute;
    top: -85px;
    right: -40px;
    padding: 10px;
    width: 40%;
    height: 100vh;
    background-color: white;
    box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.25);
    h2{
        font-size: calcFluid(15, 20, 320, 1920);
        margin: 10px 0;
    }
    .katalog-name {
        @extend %d-col;
        label{
            width: 100%;
            font-size: calcFluid(15, 20, 320, 1920);
            margin: 7px 0;
        }
    }
    .katalog-row{
        display: flex;
        flex-direction: row;
    }
    .filter__close{
        text-align: center;
        align-items: center;
        display: flex;
        font-size: calcFluid(28, 30, 320, 1920);
        margin: auto 0px auto auto;
        font-family: 'inter-bold';
        color: black;
    }
    input[type='checkbox'] {
        margin: auto 10px auto 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        display: inline-block;
        width: 1.5em;
        height:1.5em;
        border: 1px solid #adb5bd;
        cursor: pointer;
        vertical-align:bottom;
        &:checked{
           background: $gradient;
        }
    }
    input[type="checkbox"]:after {
        content: ' ';
        position: relative;
        left: 35%;
        top: 10%;
        width: 15%;
        height: 50%;
        border: solid #fff;
        border-width: 0 3px 3px 0;
        transform: rotate(50deg);
        display: none;
        }
        
        input[type="checkbox"]:checked:after {
        display: block;
        }
}

.cards::-webkit-scrollbar {
    display: none;
}