@import "../../widgits/css/general.scss";

.containerLoadingAdmin{
    display: flex;
    @extend %d-col;
    .fd-colAdmin{
         @extend %d-col;
        width: 100%;
    }
    .fd-rowAdmin{
         @extend %d-row;
         width: 100%;
         margin: 0 ;
    }
    .cardLoadingAdmin{
        border-radius: 6px;
        margin: 10px 0;
        width: 99%;
        box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.25);
        background-color: white;
        position: relative;
        box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.20);
        @extend %d-row;
        background-color: white;
        padding:10px;
        .blockLoadingAdmin{
            background: linear-gradient(90deg, #E3E3E3 0%, rgba(141, 141, 141, 0.5) 100%);
            border-radius: 6px;
            background-size: 400% 400%;
            width: 100%;
            transform: translate3d(0, 0, 0);
            animation: gradient 2s ease infinite;
        }
        @keyframes gradient {
            0% {
                background-position: 0% 50%;
            }
            50% {
                background-position: 100% 50%;
            }
            100% {
                background-position: 0% 50%;
            }
        }
        .imgAdmin{
            width: 25%;
            height:calcFluid(80, 150, 320, 1920);
            margin: 0 10px 0 0;
        }
        .starAdmin,
        .textAdmin,
        .priceAdmin,
        .btnAdmin{
            width: 100%;
            height: calcFluid(35, 67, 320, 1920);
         }
         .btnAdmin{
            margin: auto 0 0 0;
         }
         .textAdmin{
          margin: 0  calcFluid(8, 15, 320, 1920) 0 0;
         }
         .priceAdmin{
             margin: 0;
          }
    }
}