@import "../../widgits/css/general.scss";
.middle,
.katalog{
    padding: 0  calcFluid(8, 200, 320, 1920);
    .slider{
        @extend %slider;
        .katalog-line{
            width: 300%;
        }
        padding: 20px 0;
        margin:0;
    }
 
    .card{
            margin: 0 calcFluid(6, 27, 1050, 1920) 0 0;
            .more{
                @extend %more;
                margin:20px auto;
                color: white;
        
            }
            .card__bag{
                color: white;
                width: auto;
                text-decoration: none;
                padding: 10px ;
                font-size: calcFluid(10, 14, 320, 1920);
            }
            .radius{
                background-color: white;
            }
        }
       
        .prev,
        .next{
            cursor: pointer;
            background: #cacaca;
            color: #ffffff;
            border: none;
            font-family: "inter-bold";
            padding: 0px 7px 7px 7px;
            font-size:  calcFluid(20, 30, 320, 1920);
            margin: auto 0 auto 20px;
        }
        .prev{
            margin: auto 0 auto auto;
        }
        
        .next__active,
        .prev__active{
            background:$gradient;
        }
}

@media(max-width: 1790px){
    .katalog{
        .slider .card{
           width: calcFluid(150, 270, 660, 1790);
           margin: 0 calcFluid(7, 30, 1050, 1920) 0 0;
           .card__sale{
            flex-direction: column;
            }
            .card__bag{
                text-align: center;
                margin: 10px 0 0 0;
            }
        }
       
        
    }
}
@media(max-width: 1050px){
    .katalog{
        .slider .card{
           width: calcFluid(170, 258, 660, 1050);
           margin: 0 calcFluid(18, 22, 320, 1050) 0 0;
        }
        .slider{
            .katalog-line{
                width: 400%;
            }
        }
        
    }
}
@media(max-width: 730px){
    .katalog{
        .slider .card{
           width: calcFluid(170, 258, 660, 1050);
        }
        .slider{
            overflow: scroll;
            position: relative;
            display: flex;
        }
        
    }
}

@media(max-width: 630px){
    .katalog{
        .slider{
            .katalog-line,
            .block-line{
                display: flex;
                flex-direction: row;
                width: 100%;
                flex-wrap: wrap;
                align-items: center;
                justify-content: left;
                .card{
                    margin: 0 calcFluid(3, 5, 320, 630) 12px calcFluid(3, 5, 320, 630);
                    width: calcFluid(140, 270, 320, 630) !important;
                }
            }
        }
        
    }
}