@import "../../widgits/adminScss/layOut.scss";

h1{
    font-family: "Inter-Bold";
    font-size:calcFluid(17, 30, 320, 1920) ;
}

.chat{
    display: flex;
    flex-direction: column;
    .bag__block{
        @extend %bag__block;
        .card__img{
            background:  #d9d9d9;
            border-radius: 5px;
            padding: 10px 20px;
            width: 10%;
        }
        .block__info__user{
            display: flex;
            flex-direction: column;
            width: 30%;
            justify-content: center;
            margin: 0 0 0 10px;
            .user__row{
                display: flex;
                flex-direction: row;
                margin: 10px 0;
                p{
                    margin: 0;
                }
                span{
                    font-family: 'inter-bold';
                }
            }
        }
        .block__content{
            width: 60%;
            margin: 0 0 0 20px;
        }
    }
}