@import "../../widgits/css/general.scss";
main{
    @extend %main;
}
.main_fixed{
    margin-top: 90px
}
.arrange__wrapper{
    .error{
        font-size: calcFluid(13, 18, 320, 1920);
        color: red;
        margin: 10px 0 0 0;
    }
    span a{
        text-decoration: none;
        color: black;
        font-size: calcFluid(12, 20, 320, 1920);
    }
    h1{
        font-size:calcFluid(20, 35, 320, 1920);
    }
    h2{
        font-size:calcFluid(18, 30, 320, 1920);
    }
    .input__price,
    .input__delivery,
    .input__discount {
        @extend %d-row;
        margin: 10px 0;
        font-size: calcFluid(20, 25, 320, 1920);
        font-family: "inter-bold";
        text-align: center;
        p {margin: 0;}
        #price {
            font-size: calcFluid(22, 30, 320, 1920);
            font-family: "inter-bold";
        }  
        span {
            margin: 0 0 0 auto !important;
            font-size: calcFluid(18, 20, 320, 1920);
            font-family: "inter-bold";
        }              
    }
   
    .input__delivery {
        margin: 0;
        p{
            font-size: calcFluid(18, 22, 320, 1920);
        }
    }
    .input__discount{
        margin: 10px 0;
        p{
            font-size: calcFluid(18, 22, 320, 1920);
        }
        #discount {
            font-size: calcFluid(18, 22, 320, 1920);
            color: #f91155;
        }
    }

    .bag__block {
        border-radius: 8px;
        width: 95%;
        margin: 5px 0;
        @extend %d-row;
        box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.20);
        padding: 10px;
        text-decoration: none;
        margin: 5px 0;
        .block__radius{
            width: 30%;
            align-items:center ;
            justify-content: center;
            background-color: #ececec;
            padding: calcFluid(5, 10, 320, 1920);
            border-radius: 6px;
            margin: 5px 0;
            display: flex;
            .img__emptyPhoto{
                margin: auto;
            }
            .block__img {
                img{
                    max-width: auto;
                    max-height: calcFluid(120, 145, 320, 1920);
                    margin: 0;
                }
            }

        }
        .block__content {
            position: relative;
            margin: 0 0 0 10px;
            width: 70%;
            @extend %d-col;
            color: black;

            .block__close {
                right: 0;
                top: -15px;
                cursor: pointer;
                font-size: calcFluid(18, 25, 320, 1920);
                font-family: "inter-bold";
                color: #58585e;
                position: absolute;
            }

            .rent {
                color: #58585e;
                font-family: "inter-regular";
                margin: calcFluid(0, 20, 320, 1920) 0 0 0;
            }

            p,a {
                text-decoration: none;
                color: black;
                font-size: calcFluid(20, 20, 320, 1920);
                font-family: "inter-bold";
                margin: 10px 0;
            }

            .block__input {
                margin: auto 0 0px 0;
                @extend %d-row;
                .quantity_innerContainer{
                    display: flex;
                    flex-direction: row;
                }
                .quantity_inner {
                    display: flex;
                    flex-direction: row;
                    margin: auto 10px auto 0;
                    padding: 0 5px;
                    border-radius: 5px;
                    text-align: center;
                    align-items: center;
                    border: 0.6mm solid #D3D3D3;
                    .bt_minus,
                    .bt_plus,
                    .bt_minusMonth,
                    .bt_plusMonth,
                    .quantity {
                        user-select: none;
                        padding: 0;
                        border: 0;
                        margin: 0;
                        background: transparent;
                        cursor: pointer;
                        outline: 0;
                        font-family: "inter-regular";
                        font-size: calcFluid(20, 35, 320, 1920);
                    }

                    .bt_plus,
                    .bt_plusMonth {
                        font-size: calcFluid(30, 35, 320, 1920);
                        background: $gradient;
                        -webkit-background-clip: text;
                        background-clip: text;
                        -webkit-text-fill-color: transparent;
                        &:active{
                            transform: scale(0.80);
                        }
                    }
                    .bt_minus,
                    .bt_minusMonth {
                        font-size: calcFluid(30, 35, 320, 1920);
                        &:active{
                            transform: scale(0.80);
                        }
                    }

                    .fd-col {
                        @extend %d-col;
                        margin: 0;
                        font-size: calcFluid(9, 13, 320, 1920);
                        text-align: center;
                        .quantity {
                            width: 50px;
                            text-align: center;
                            font-size: calcFluid(17, 25, 320, 1920);
                            font-weight: bold;
                            color: #000;
                            margin: 0;
                            width: 100%;
                        }  
                    }
                }
                .block-col {
                    margin: auto 0 0 auto;

                    .block__price,
                    .block__discount {
                        margin: 5px 0 0 0;
                    }

                    .block__discount {
                        font-size: calcFluid(10, 18, 320, 1920);
                        text-decoration: line-through;
                        color: #58585e;
                    }
                }
            }
        }
    }        
    padding: 0 calcFluid(8, 200, 320, 1920);
    @extend %d-col;

    .delivery__wrapper{
        @extend %d-row;
        .delivery{
            cursor: pointer;
            width: 50%;
            text-decoration: none;
            color: black ;
            padding: calcFluid(8, 10, 1024, 1920) 20px;
            border-radius: 4px;
            font-size:calcFluid(15, 20, 320, 1920);;
            text-align: center;
            border: 0px solid black;
            font-family: "inter-regular";
            margin: auto 4px;
            transition: 0.4s;
        }
       .active{
            background: $gradient;
            color: white;
       }     
    }
    .order {
        @extend %more;
        margin: 10px auto auto 0;
        width: 100%;
    }
    .loading{
       background: rgb(165, 165, 165) !important;
    }
    .map1{
        @extend %d-row;
        .map__contact{
            @extend %d-col;
            width: 30%;
            position: relative;
            .map-col{
                margin: auto auto auto 0;
                @extend %d-col;
                h2{
                    font-size: calcFluid(14, 20, 320, 1920);
                    font-family: "inter-regular";
                    opacity: 0.4;
                }
                p{
                    margin: 0;
                    font-size: calcFluid(14, 20, 320, 1920);
                    font-family: "inter-regular";
                }
            }
            .map-col:first-child{
                margin: 0 auto auto 0;
            }
        }
        #map__yandex{
            width: 70%;
            height: 350px;
        }
        [class*="copyrights-pane"]{
            display: none !important;
        }
    }
    .delivery__input{
        @extend %d-col;
        input{
            box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
            margin: 10px 0;
            padding: 13px;
            outline: none;
            border: none;
            font-size: calcFluid(14, 20, 320, 1920);
        }
    }
}

.mobile__bag{
    .order{
        @extend %more;
        margin: 17px 17px auto auto;
    }     
}

@media(max-width: 630px) {
    .arrange__wrapper{
        .order{
            margin: 10px auto 50px 0;
        }
       .map1{
        #map__yandex{
            border: 2px solid #EFEFEF;
            width: 100%;
            height: 350px;
        }
       }
    }
}

@media(max-width: 540px) {
    .arrange__wrapper{
        .bag__block {
            .block__content .block__input{
                flex-direction: column-reverse;
                .block-col{
                    margin: 0 0 5px 0;
                    .block__discount{
                        text-align: start;
                    }
                }
            }
        }
    }
}