@import "../../widgits/css/general.scss";
main{
    @extend %main;
}
.main_fixed{
    margin-top: 90px
}
.loginClient{
    padding: 0 calcFluid(8, 400, 320, 1920);
    @extend %d-col;
    h1{
        margin: 0 0 15px 0;
        font-size:calcFluid(20, 35, 320, 1920);
    }
    .login-row{
        @extend %d-col;
        input{
            border-radius: 6px;
            -webkit-appearance: none;
            padding: 15px 10px;
            margin: 5px 0;
            outline:none;
            border: none;
            -moz-box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.20);
            box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.20);
            -webkit-box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.20);
            font-size:calcFluid(14, 16, 320, 1920);
        }
        .loginBTN{
            @extend %more;
            margin:10px 0;
            text-align: center;
        }
        .loading{
           background: none;
           background-color: rgb(165, 165, 165);
        }
        .error{
            font-size: calcFluid(14, 18, 320, 1920);
            color: red;
            margin: 10px 0 0 0;
        }
    }
    .account{
        font-size: calcFluid(13, 16, 320, 1920);
        a{
            text-decoration: none;
        }
    }
}

@media(max-width: 630px){
    .loginClient{
        padding: 120px calcFluid(8, 400, 320, 1920);
    } 
}