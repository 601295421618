@import "../../widgits/css/general.scss";

.modalImg{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    margin: auto;
    display: flex;
    flex-direction: row;
    background: rgba(0, 0, 0, 0.05);
    top: 0;
    left: 0;
    .modalPrev, .modalClose{
        cursor: pointer;
        font-family: 'inter-bold';
        font-size: 22px;
        color: rgb(114, 114, 114);
        margin: auto 30px;
        padding: 15px   ;
        text-align: center;
        justify-content: center;
        background-color: #c5c5c5;
        border-radius: 15px;
    }
    .modalClose{
        margin: 0;
        right: 30px;
        top: 10px;
        position: absolute;
    }
    img{
        cursor: pointer;
        background-color: white;
        border: 2px solid #773cbc;
        border-radius: 15px;
        max-width: auto;
        max-height: calcFluid(275, 750, 320, 1920);
        margin: auto;
    }
}

.spec__row{
    @extend %spec__row;
    margin: 15px 0;
}
.transportation_wrapper{
    padding: 0 calcFluid(8, 200, 320, 1920);
    @extend %d-col;
    position: relative;
    .line{
        width: 100%;
        margin-top: 30px;
        border-bottom: 1px solid #ECECEC;
    }
    span a{
        text-decoration: none;
        color: black;
        font-size: calcFluid(12, 20, 320, 1920);
    }
   
    .transportation{
        @extend %d-row;
        margin-top: 50px;
        &__block{
            @extend %d-row;
            width: 60%;
            box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.25);
            padding: 20px;
            .slider-wrapper{
                width: 25%;
                @extend %d-col;
                .slider{
                    @extend %slider;
                    @extend %d-col;
                    height: calcFluid(275, 380, 1024, 1920);
                    margin: auto;
                    .slider-line{
                        @extend %d-col; 
                        margin: auto;
                        width: 100%;
                        top: 0;
                        height: 200%;
                        .slider__block__img{
                            cursor: pointer;
                            max-width: calcFluid(100, 192, 320, 1920);
                            max-height: calcFluid(50, 115, 320, 1920);
                            display: flex;
                            margin: 10px auto 0 auto;
                            border: 3px solid rgb(206, 206, 206);
                            box-sizing: border-box;
                            padding: 10px 2px;
                        }
                        .slider__img{
                            margin: 0;
                            max-width: auto;
                            max-height: auto;
                            object-fit: cover;
                        }
                        .bigMargin{
                            margin: auto;
                        }
                        .active{
                            border: 3px solid;
                            border-image-source:$gradient;
                            border-image-slice:1;
                        }
                    }
                }
                .prev,
                .next{
                  transform: rotate(90deg);
                  margin: auto;
                  font-size: calcFluid(20, 30, 320, 1920);
                  color: gray;
                  font-family: "inter-bold";
                  cursor: pointer;
                }
                .active{
                    background:$gradient;
                    -webkit-background-clip: text;
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                    color: #5134c4;
                }
            }
            .transportation__img{
                width: 80%;
                @extend %d-col; 
                .star{
                    margin: 0 0 0 auto;
                    font-family: "inter-bold";
                    font-size: calcFluid(17, 23, 320, 1920);
                }
                .main__img{
                    cursor: pointer;
                    margin:auto auto 0 auto;  
                    max-width: auto;
                    max-height: calcFluid(200, 250, 320, 1920);
                }
                .card__img{
                    max-height: calcFluid(80, 172, 320, 1920);
                }
                .img__empty{
                    margin: auto 0;
                    display: flex;
                    width: calcFluid(250, 450, 320, 1920);
                    height: calcFluid(125, 225, 320, 1920);
                    background-color: #E3E3E3;
                    border-radius: 6px;
                    .img__emptyPhoto{
                        margin: auto;
                    }
                }
                .chooseMore{
                    margin: auto 0 0 auto;
                    padding: calcFluid(5, 10, 320, 1920) calcFluid(10, 20, 320, 1920);
                    border: 1px solid;
                    border-image-source:linear-gradient(90deg, #5134c4,#773cbc,#b856d4);
                    border-image-slice:1;
                    background: transparent;
                    text-decoration: none;
                    color: black
                }
            }
        }
        &__contant{
            @extend %d-col; 
            margin: 0 0 0  calcFluid(20, 40, 320, 1920);
            width: 40%;
            h1{
                margin: 0 0 20px 0;
                font-size: calcFluid(25, 32, 320, 1920);
            }
            p{
                font-size: calcFluid(15, 18, 320, 1920);
                color: #58585e;
                font-family: "inter-regular";
                margin: 7px 0;
                span{
                    color: black;
                }
            }
            .transportation__row{
                @extend %d-row;
                margin: auto 0 0 0;
                p:nth-child(1){
                    font-size:calcFluid(20, 30, 320, 1920) ;
                    color: black;
                    margin: auto 0 10px 0;
                    font-family: "inter-bold";
                }
                p:nth-child(2){
                    font-size:calcFluid(17, 25, 320, 1920);
                    color: #58585e;
                    margin: auto 0 10px 10px;
                    text-decoration-line: line-through;
                    font-family: "inter-bold";
                }
            }
            .fd-rowInner{
                display: flex;
                flex-direction: row;
                margin: 10px 0 ;
            }
            .quantity_inner{
                display: flex;
                flex-direction: row;
                margin: auto 10px auto 0;
                padding: 0 5px;
                border-radius: 5px;
                text-align: center;
                align-items: center;
                border: 0.6mm solid #D3D3D3;
                .bt_minus,
                .bt_plus,
                .bt_minusMonth,
                .bt_plusMonth,
                .quantity  {
                    padding: 0;
                    border: 0;
                    margin: 0;
                    background: transparent;
                    cursor: pointer;
                    outline: 0;
                    font-family: "inter-regular";
                    font-size: calcFluid(20, 35, 320, 1920);
                    margin: 0; 
               }
               .bt_plus,
               .bt_plusMonth{
                    margin: 0 calcFluid(2, 7, 320, 1920);
                    background:$gradient;
                    -webkit-background-clip: text;
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-size: calcFluid(30, 40, 320, 1920);
                    &:active{
                        transform: scale(0.80);
                    }
               }
               .bt_minus,
               .bt_minusMonth{
                margin: 0 calcFluid(2, 7, 320, 1920);
                font-size: calcFluid(30, 40, 320, 1920);
                &:active{
                    transform: scale(0.80);
                }
               }
               .fd-col{
                @extend %d-col;
                margin: 0;
                font-size: calcFluid(12, 15, 320, 1920);;
                text-align: center;
                .quantity {
                    width: 50px;
                    text-align: center;
                    font-size: calcFluid(17, 30, 320, 1920);
                    font-weight: bold;
                    color: #000;
                    margin: 0 15px;
                }
               }

            }    
            .more{
                @extend %more;
                cursor: pointer;
                margin: 10px auto 0 0;
            }     
            .moreActive{
                background:transparent;
                border: 1px solid;
                border-image-source:linear-gradient(90deg, #5134c4,#773cbc,#b856d4);
                border-image-slice:1;
                background: transparent;
                text-decoration: none;
                color: black;
            }
        }
    }
    .transportation__info{
        h2{ font-size: calcFluid(20, 25, 320, 1920); margin: 18px 0 10px 0;}
        h3{ font-size: calcFluid(15, 20, 320, 1920); margin: 0;}
        width: auto;
        p{
            font-size:calcFluid(12, 18, 320, 1920) ;
        }
    }
}

.mobile__bag{
    .more{
        @extend %more;
        margin: 17px 17px auto auto;
    }     
    .moreActive{
        margin: 17px 17px auto auto;
        background:transparent;
        border: 1px solid;
        border-image-source:linear-gradient(90deg, #5134c4,#773cbc,#b856d4);
        border-image-slice:1;
        background: transparent;
        text-decoration: none;
        color: black;
    }
    .mobile__discount{
        color: #58585e !important;
        text-decoration-line: line-through !important;
    }
}
.furniture {
    padding:  calcFluid(8, 20, 320, 1920) 40px  calcFluid(8, 20, 320, 1920) calcFluid(8, 200, 320, 1920) ;
    background-color: #ececec;
    @extend %d-row;
    margin-right: calcFluid(8, 200, 320, 1920);
    .content{
        margin: auto 0;
        padding: 0;
        width: 35%;
        p{
            display: block;
        }
    }
    .card {
        box-shadow: 3px 1px 10px rgba(0, 0, 0, 0.25);
        @extend %card;
        border-radius: 0px;
        width: calcFluid(70, 250, 320, 1920);
        .card__img{
            width: 60%;
            margin:  auto auto 0 auto ;
        }
        .card__bag{
            position: absolute; 
            bottom: -20px;
            right: 10px;
            border-radius: 50%;
            padding: 0;
            background: none;
            border:2px solid #5134c4;
            font-size: 20px;
            text-align: center;
            align-items: center;
            width:  calcFluid(30, 35, 320, 1920);
            height:  calcFluid(30, 35, 320, 1920);
        }
    }
    
    .slider{
        padding: 20px 0 20px 0;
        @extend %slider;
        width: 65%;
    }
    h2{
        font-family: "inter-regular";
        font-size: calcFluid(13, 30, 320, 1920);
    }
    p{
        font-size: calcFluid(13, 20, 850, 1920);
        margin: 0 0 20px 0 ;
    }
    .prev__active,
    .next__active{
        @extend %sliderBtn;
    }
    .fd-row{
        @extend %d-row
    }
    .prevFurniture, 
    .nextFurniture{
        color: gray;
        padding: 0px 5px 5px 5px;
        font-size: calcFluid(25, 30, 1024, 1920);
        margin: 20px 0 0 10px;
    }
    .prev{
        margin: 20px 0 0 0;
    }
}
@media(max-width: 1024px){
    .transportation_wrapper {
        .transportation{
            display: flex;
            flex-direction: column;
            .transportation__block{
                width: 100% !important;
                padding: 0;
                .slider-wrapper{
                    width: 35%;
                    
                }
                .transportation__img{
                    width: 80%;
                    .star{
                        margin: 10px 10px 0 auto;
                    }
                    .chooseMore{
                        margin: auto 10px 10px auto;
                    }
                }
            }
            .transportation__contant{
                width: 100%;
                margin: 23px 0 0 0 !important; 
                h1{
                    margin: 0 0 0px 0;
                    font-size: calcFluid(22, 27, 320, 630);
                }
                .star__wrapper{
                    margin: 15px 0;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    text-align: center;
                    .star{
                        font-size: calcFluid(21, 25, 320, 630);
                    }
                    p{  
                        color: #000;
                        margin: 0 0 0 10px;
                        font-size: calcFluid(15, 18, 320, 630);
                        span{
                            color: #A9A9A9;
                        }
                    }
                    
                }
                .transportation__row{
                    margin: auto 0 0 0;
                    p:nth-child(1){
                        font-size:calcFluid(24, 26, 320, 630) ;
                        margin: auto 0 auto 0;
                    }
                    p:nth-child(2){
                        font-size:calcFluid(17, 22, 320, 630);
                        margin: auto 0 auto 10px;
                    }
                }
            }
        }
        .transportation__info{
            width: auto;
            padding: 0;
            .transportation__text{
                width: 100%;
                font-size: calcFluid(13, 16, 320, 630);
            }
            .transportation__caract{
                display: flex;
                color: #A9A9A9;
                font-size: calcFluid(13, 16, 320, 630);
                span{
                    color: #000;
                    margin: 0 0 0 auto;
                }
            }
        }
    }
}

@media(max-width: 850px){
    .furniture {
        margin-top: 40px;
        .slider{
            .card{
                .card__img{
                    max-width: 90%;
                }
            }
            .furniture-line{
                width: 300%;
            }
        }
    }
}
@media(max-width: 630px){
    .transportation_wrapper{
        padding: 0;
        .transportation{
            margin: 0;
            .chooseMore{
                margin: auto 0 auto 0;
                text-align: center;
                font-size: calcFluid(13, 16, 320, 630);
                padding: calcFluid(5, 10, 320, 1920) calcFluid(10, 20, 320, 1920);
                border: 1px solid;
                border-image-source:linear-gradient(90deg, #5134c4,#773cbc,#b856d4);
                border-image-slice:1;
                background: transparent;
                text-decoration: none;
                color: black
            }
            .transportation__block{
                
                display: flex;
                flex-direction: column;
                box-shadow: none;
                background-color: #EFEFEF;
                .slider-wrapper{
                    width: 100%;
                    padding: 30px 0 ;
                    .slider{
                        width: calcFluid(300, 500, 320, 630);
                        height: auto;
                        margin: auto;
                        overflow: scroll;
                        .slider-line{  
                            flex-direction: row;
                            margin: auto;
                            width:600%;
                            height: 100%;
                            top: 0;
                            .slider__block__img{
                                padding: 0;
                                margin: 0 10px;
                                max-width: none;
                                width: calcFluid(290, 480, 320, 630) !important;
                                display: flex;
                                border: none;
                                max-height: 100%;
                            }
                            .slider__img
                            {   
                                margin: auto; 
                                max-width: auto;
                                padding: 0;
                                border: none !important;
                                box-sizing: border-box;
                            }
                        }
                    }
                }
                .block__circle__wrapper{
                    display: flex;
                    flex-direction: row;
                    margin: auto 20px 20px auto;
                    .block__circle{
                        margin: 0 3px;
                        width: 12px;
                        height: 12px;
                        background-color: #BABABA;
                        border-radius: 50%;
                    }
                    .block__circle:first-child{
                        background-color: #843EB7;
                    }
                }
            }
            &__contant{
                width: auto !important;
                padding: 0 calcFluid(8, 20, 320, 630);
                .more{
                    width: 100%;
                    border-radius: 2px;
                }
            }   
        }
        .transportation__info{
            padding: 0 calcFluid(8, 20, 320, 630) !important;
        } 

    }
}