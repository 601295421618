@import "./general.scss";
@font-face {
    font-family: "inter-light";
    font-style: normal;
    font-weight: 100;
    src: url("../../assets/fonts/Inter/Inter-Light.ttf");
    font-display: swap;
}
@font-face {
    font-style: normal;
    font-family: "inter-regular";
    font-weight: 500;
    src: url("../../assets/fonts/Inter/Inter-Regular.ttf");
    font-display: swap;
}
@font-face {
    font-style: normal;
    font-family: "inter-bold";
    font-weight: 900;
    src: url("../../assets/fonts/Inter/Inter-Bold.ttf");
    font-display: swap;
}
@font-face {
    font-style: normal;
    font-family: "fouore";
    src: url("../../assets/fonts/fouore/Furore.otf");
    font-display: swap;
}

.search__modal{
    @extend %search;
    display: flex;
    flex-direction: column;
    position: fixed;
    background-color: white;
    height: 100%;
    width: 100%;
    z-index: 100;
    top: 0;
    left: 0;
    .search__arrow{
        position: absolute;
        margin: 0;
        top: calcFluid(25, 25, 320, 1024);
        left: 15px;
        font-size:calcFluid(30, 35, 320, 1024);
        color: gray;
    }
    .search{
        width:  calcFluid(245, 885, 320, 1024);
        margin: 20px 20px 20px 60px;
        font-size: calcFluid(18, 20, 320, 1920);
    }
    img{
        right: calcFluid(30, 87, 320, 1024);
        top: 30px;
    }
    p{
        margin: auto 20px auto 0px;
        font-size: calcFluid(18, 20, 1024, 1920);
    }
    .search__block{
        width: 92%;
        box-shadow: none;
        position:static;
        margin: 0 12px;
        overflow-y:visible;
        .search__blockHref{
            padding: 11px;
            border-top: 1px solid gray;
            font-size: calcFluid(18, 20, 1024, 1920);
        }
    }
}

.katalog__modal{
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 100vh;
    top:calcFluid(59, 69, 320, 1920);
    left: 0;
    background-color: white;
    z-index: 100;
    position: fixed;
    .sidebar__p{
        cursor: pointer;
        text-decoration: none;
        color: black;
        text-align: left;
        align-items: center;
        display: flex;
        height: 50px;
        padding:0 0 0 calcFluid(5, 300, 320, 1920);
        p{
            width: 100%;
            margin: 0;
            font-size: calcFluid(16, 20, 1024, 1920);
            font-family: 'inter-regular';
        }
    }
    .active{
        border-right:2px solid #A651D4 ;
        background-color: white;
        p{
            color: #A651D4;
        }
    }
    
    .modal__sideBar{
        width: 33%;
        height: 100vh;
        background-color: #F3F3F7; 
       
    }
    .modal__aside{
        width: 65%;
        padding: 10px 0 0 30px;
        .aside__block{
            display: flex;
            flex-direction: column;
            h4{
                margin: 0 0 10px 0;
                font-size: calcFluid(16, 20, 1024, 1920);
                font-family: 'inter-regular';
            }
            .lastA{
                font-family: 'inter-regular';
                color:#5134c4;
            }
            a{
                font-size: calcFluid(14, 16, 1024, 1920);
                text-decoration: none;
                color: black;
                font-family: 'inter-light';
                margin: 10px 0; 
               
                &:hover{
                    color: #A651D4;
                }
            }
            .block__col{
                display: flex;
                flex-direction: row;
               
                .block__row{
                    display: flex;
                    flex-direction: column;
                    width: 33%;
                }
                &:first-child{
                    margin: 0 0 30px 0;
                }
            }
        }
      
    }
}

.header {
    position: relative;
    padding: 20px calcFluid(0, 100, 1024, 1920);
    display: flex;
    border: none;
    flex-direction: column;
    .headerTop {
        @extend %d-row;
        padding: 0 calcFluid(5, 200, 320, 1920) calcFluid(8, 15, 320, 1920);
        border-bottom: 1px solid $gray;
        &__logotip{
            display: flex;
            flex-direction: row;
            height: 100%;
            width:calcFluid(45, 60, 320, 1920);
            img{
                width: 100%;
                max-height: 100%;
            }
            margin: calcFluid(6, 10, 320, 1920) 15px 0 0;
        }
        &__logo {
            @extend %general__logo;
            color: black;
            margin: auto 0;
        }
        &__search {
            @extend %d-row;
            position: relative;
            margin: auto calcFluid(10, 40, 1024, 1920);
            @extend %search;

        }

        &__contacts {
            @extend %d-col;
            p {
                font-family: 'inter-regular';
                margin: auto 0;
                font-size: calcFluid(15, 18, 320, 1920);
            }
        }

        &__log {
            @extend %d-row;
            margin-left: auto;
            .login,
            .register {
                @extend %general__log;
            }
            .register {
                border: 1px solid;
                border-image-source:  $gradient;
                border-image-slice: 1;
                &:hover {
                    background: $gradient;
                    color: white;
                }
            }
            .login {
                &:hover {
                    background-color: black;
                    color: white;
                }
            }
            .loginUser{
                display: flex;
                margin: auto 0 auto auto;
                @extend %d-row;
                text-decoration: none;
                color: black;
                font-size: calcFluid(15, 25, 320, 1920);
                p{
                    margin: auto 0;
                    width: 100%;
                }
                img{
                    margin: auto 10px 0 0;
                    border: 2px solid gray;
                    border-image-source:$gradient;
                    border-image-slice:1;
                    background:$gradient; 
                    -webkit-background-clip: text;
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                    width: 43%;
                }
            }
        }
    }
    .services {
        z-index: 10;
        overflow:hidden ;
        @extend %d-row;
        padding: 0 calcFluid(10, 200, 360, 1920);
        .services__katalog {
            cursor: pointer;
            @extend %d-row;
            background: $gradient;
            color: white;
            padding: 10px;
            display: flex;
            margin: auto 0;
            .menu{
                @extend %iconMenu
            }
            background-size: 150% 100%;
            border: none;
            transition: all .4s ease-in-out;
            &:hover {
                background-position: 100% 0;
            }
            
            &:focus {
                outline: none;
            }
            img{
                margin: 0 0 0 10px;
                height: 20px;
            }
        }
        .bag__headerMargin {
            @extend %d-row;
            position: relative;
            margin-left: auto;
            img {
                max-height: 100%;
                max-width: 100%;
                margin: 0 10px 1px 0;
            }
        }
        a {
            cursor: pointer;
            margin: calcFluid(16, 40, 320, 1920) 0 calcFluid(0, 35, 320, 1920)  calcFluid(5, 40, 900, 1920);
            font-size: calcFluid(15, 19, 320, 1920);
            font-family: 'inter-regular';
            border-bottom: 2px solid $gray;
            text-decoration: none;
            color: black;
            transition: 0.3s;

           
            &:not(:first-child):hover {
                border-bottom: 2px solid;
                border-image-source:  $gradient;
                border-image-slice: 1;
                margin-top: calcFluid(13, 35, 320, 1920);
                margin-bottom:  calcFluid(15, 40, 320, 1920);
            }
        }
        &__sum {
            position: absolute;
            top: -15px;
            left: 15px;
            font-size: 14px;
            color: white;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background: $gradient;
            text-align:center;
            font-family: 'inter-regular';
            p{
                margin: 0;
            }
        }
        &__media{
            display: none;
            border: none !important;
        }
        .sidebar__open{
            display: none;
            @extend %iconMenu;
            border-top: 2px solid rgb(0, 0, 0);
            border-bottom: 2px solid rgb(0, 0, 0);
            span{
                border: 1px solid rgb(0, 0, 0);
            }
        }
        
        .sidebar{
            z-index: 10;
            top: 0;
            right: 0;
            position: fixed;
            width: 40%;
            height: 100%;
            background-color: white;
            border: 2px solid $gray;
            padding: 25px 15px;
            &__menu{
               @extend %d-col;
                .sidebar__row{
                    @extend %d-row;
                    a,
                    .sidebar__katalogBTN{
                    
                    @extend %d-row;
                    font-family: "Inter-Light";
                    font-size: calcFluid(13, 19, 320, 1920);
                    background: $gradient;
                    color: white;
                    padding: 10px;
                    margin: auto 0;
                    .iconMenu{
                        @extend %iconMenu
                    }
                    }
                    .sidebar__close{
                        cursor: pointer;
                        margin: auto 0 auto auto;
                        font-size:calcFluid(22, 30, 320, 1920);
                        font-family: "Inter-Light";
                        color: black;
                    }
                }
                a{  
                    display: flex !important;
                    font-size:calcFluid(15, 20, 320, 1920);
                    font-family: 'inter-regular';
                    color: black;
                    margin: 12px 0;
                    border-bottom: 2px solid $gray;
                    text-decoration: none;
                    transition: 0.3s ease;
                    &:not(:first-child):hover{
                        border-bottom: 2px solid;
                        border-image-source:  $gradient;
                        border-image-slice: 1;
                        margin: 15px 0 ;
                    }
                }
            }
        }
    }
    .services__fixed{
        border-bottom: 1px solid $gray;
        position: fixed;
        width: calcFluid(300, 1320, 320, 1920);
        padding:0 calcFluid(10, 300, 320, 1920);
        top: 0;
        left: 0;
        background-color: white;
        z-index: 10;
        a{
            margin: 20px 0 calcFluid(10, 20, 320, 1920) auto;
            &:not(:first-child):hover{
                margin: 15px 0 25px 40px; 
            }
        }
        .bag__headerMargin:hover{
            margin: 15px 0 25px auto !important;
        }
    }
    .mobile__header{
        display: flex;
        flex-direction: row;
        padding: 0 12px 10px 12px;
        border-bottom: 1px solid #D3D3D3;
        background-color: white;
        .mobile__logo{
            width: calcFluid(37, 40, 320, 630);
        }
        .mobile__header__text,
        .mobile__header__mainText{
            -webkit-appearance: none;
            margin: auto;
            text-align: center;
            background:$gradient;
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            width: calcFluid(200, 265, 320, 630);
            color: #5134c4;
            font-size: calcFluid(18, 25, 320, 630);
            font-family: "inter-bold";
            white-space: nowrap; 
            overflow: hidden;
            -webkit-overflow: hidden;
            text-overflow: ellipsis;
            -webkit-text-overflow: ellipsis;
        }
    }
    .existRegiser{
        width: 18px;
        padding: 10px;
        cursor: pointer;
        font-family: 'inter-bold';
        font-size: calcFluid(17, 20, 320, 630);;
        color: black;
        margin: 0 0 0 calcFluid(8, 80, 320, 630);
        text-align: center;
        justify-content: center;
        background-color: white;
        border-radius: 8px;
        border: 1px solid #D3D3D3;
    }
    .mobile__header__fixed{
        display: flex;
        padding: 5px 20px 5px 20px;
        position: fixed;
        z-index: 900;
        width: calcFluid(280, 590, 320, 630);
        top: 0;
        left: 0;
    }
}
@media(max-width: 1180px){
    .header{
        .headerTop{
            &__search{
                margin: auto !important;
              .search{
                width: calcFluid(120, 400, 320, 1920) !important; 
              }
            }
            &__contacts{
                display: none !important;
            }
        }
        .services a{
            margin: calcFluid(16, 40, 320, 1920) 0 calcFluid(0, 35, 320, 1920)  calcFluid(10, 50, 320, 1180);
        }
    }
}
@media(max-width: 890px){
    header{
        .services {
            a:nth-child(1),
            a:nth-child(2),
            a:nth-child(3),
            .bag__headerMargin{
                display: none;
            }
            .sidebar__open{
                display: flex !important;
            }
            .services__media{
                display: block;
                margin: auto 20px auto 40px;
                img{    
                    width: 100%;
                }
            }
        }
        .headerTop{
            .headerTop{
                &__search{
                    display: none;
                }
            }
        }
    }
}
@media(max-width: 770px){
    .header .katalog__modal{
        flex-direction: column;
        top:  calcFluid(46, 61, 320, 770);
        .modal__sideBar{
           
            width: auto;
            height: 100px;
            .sidebar__p{
                text-align: center;
                align-items: center;
            }
        }
        .active{
            border-left: 2px solid #A651D4;
        }
        .modal__aside{
            width: auto;
        }
    }
}

@media(max-width: 630px){
    .header{
        .headerTop{
           display: none;
        }
    }
}

@media(max-width: 400px){
    .search__modal{
        .search{
            width: calcFluid(235, 320, 320, 400);
        }
    }
}
