@import "./layOut.scss";
header{
    display: flex;
    flex-direction: row;
    border-bottom: 2px solid #ececec;
    .search__wrapper{
        position: relative;
        margin: auto 20px auto 0;
        .search {
            box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.25);
            border-radius: 4px;
            border: none;
            padding: 10px calcFluid(10, 50, 1024, 1920) 10px 4px;
            background-color: white;
            color: #6d6d6d;
            font-family: "Inter-Regular";
            font-size: calcFluid(12, 18, 1024, 1920);
            outline: none;
            width: calcFluid(200, 300, 1024, 1920);
        }
        .search__block{
            height: 192px;
            overflow-y: scroll;
            z-index: 20;
            position: absolute;
            display: flex;
            flex-direction: column;
            top: 50px;
            width: 100%;
            background-color: white;
            box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.25);
            a{
                text-decoration: none;
                color: black;
                font-size: calcFluid(13, 15, 1024, 1920);
                padding: 7px 10px;
                font-family: "inter-light";
                background-color: white;
                &:hover{
                    background-color: #eeeeee;
                }
            }
        }
        img {
            position: absolute;
            right: 10px;
            top: 8px;
            max-width: 100%;
            max-height: 100%;
        }
    }
    .contacts{
        display: flex;
        flex-direction: column;
        margin: auto 0 auto 10px;
        p {
            font-family: "Inter-Regular";
            margin: 5px 0;
            font-size: calcFluid(15, 18, 320, 1920);
        }
    }
    .logo{
        margin: auto 0 auto auto;
    }
}