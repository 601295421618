@import "../../widgits/css/general.scss";
main{
    @extend %main;
}
.main_fixed{
    margin-top: 90px
}

.gallary{
    padding: 0 calcFluid(8, 200, 320, 1920);
    @extend %d-col;
    span a{
        text-decoration: none;
        color: black;
        font-size: calcFluid(12, 20, 320, 1920);
    }
    h1{
        font-size:calcFluid(18, 35, 320, 1920);
    }
    h2{
        font-size:calcFluid(16, 28, 320, 1920);
    }
    .transpor__row{
        display: flex;
        flex-direction: row;
        .transpor__col{
            display: flex;
            flex-direction: row;
        }
        .transpor__photo{
            margin: 0 10px;
            img{
                object-fit: cover;
            }
            p{
                font-size:calcFluid(12, 16, 320, 1920);
                font-family: 'inter-light';
            }
            span{
                font-family: 'inter-bold';
                font-size:calcFluid(12, 16, 320, 1920);
            }
        }
        h3{
            font-size:calcFluid(16, 24, 320, 1920);
        }
        .transpor__form{
            p{
                font-size:calcFluid(12, 16, 320, 1920);
                font-family: 'inter-light';
            }
            display: flex;
            flex-direction: column;
            margin: 0 0 0 calcFluid(15, 50, 320, 1920);;
            input, button{
                border-radius: 6px;
                -webkit-appearance: none;
                padding: 15px 10px;
                margin: 10px 0;
                outline:none;
                border: none;
                -moz-box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.20);
                box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.20);
                -webkit-box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.20);
                font-size:calcFluid(14, 16, 320, 1920);
            }
            button {
                background: $gradient;
                color: white;
                cursor: pointer;
            }
        }
    }
    .reduce__container{
        display: flex;
        flex-direction: row;
        margin: 50px 0 20px 0;
        .reduce__block{
            display: flex;
            flex-direction: row;
            width: 33%;
            p{
                font-size:calcFluid(12, 16, 320, 1920);
                margin: auto 0px auto 10px;
            }
            span{
                font-size:calcFluid(12, 16, 320, 1920);
                font-family: 'inter-bold';
            }
        }
    }
    .complect__row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        .complect__block{
            display: flex;
            flex-direction: column;
            width: 20%;
            margin: 10px calcFluid(9, 10, 320, 1920);
            img{
                margin: 0 0 10px 0;
                width: 100px;
            }
        }
        p{
            font-size:calcFluid(12, 16, 320, 1920);
            margin: auto 10px;
        }
    }
    .spec__row{
        @extend %spec__row;
    }
    .gallary__choose{
        display: flex;
        flex-direction: column;
        margin: 20px 0 0 0 ;
        .choose__col{
            display: flex;
            flex-direction:  row;
            button{
                width: 100%;
                margin:10px;
                @extend %more;
                font-size: calcFluid(12, 14, 320, 1920);
                background: white;
                color: black;
                border: 1px solid black;
                padding:calcFluid(7, 14, 320, 1920);
            }
            .active{
                border: 1px solid white;
                background: $gradient;
                color: white;
            }
        }
      
    }
    .custom__row{
        display: flex;
        flex-direction: row;
        .custom__text{
            display: flex;
            flex-direction: column;
            width: 40%;
            justify-content: center;
            h2{
                font-size:calcFluid(18, 24, 320, 1920);
                margin: calcFluid(18, 30, 320, 1920) 0 10px 0 ;
                font-family: 'inter-bold';
            }   
            p{
                font-size:calcFluid(12, 16, 320, 1920);
                font-family: 'inter-light';
                margin: 5px 0;
            }
            span{
                font-family: 'inter-bold';
            }
        }
        .custom__form{
            display: flex;
            flex-direction: column;
            margin: calcFluid(18, 30, 320, 1920) 0 0 auto;
            width: 50%;
            .custom__fdRow{
                display: flex; 
                flex-direction: row;
                input:nth-child(1){
                    margin: 10px 10px 10px 0;
                    width: 50%;
                }
                input:nth-child(2){
                    width: 50%;
                }
            }
            input,
            .answer{
                border-radius: 6px;
                -webkit-appearance: none;
                padding: 15px 10px;
                margin: 10px 0;
                outline:none;
                border: none;
                -moz-box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.20);
                box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.20);
                -webkit-box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.20);
                font-size:calcFluid(14, 16, 320, 1920);
            }
        }
    }
    .answer {
        align-items: center;
        background: $gradient;
        color: white;
        cursor: pointer;
    }
    .loading{
        background: rgb(165, 165, 165) !important;
     }
    .photo__container1{
        display: flex;
        flex-direction: row;
        img:nth-child(1){
            width: 30%;
            object-fit: cover;
            border-radius: 10px;
            margin: 0 15px 15px 0;
        }
        img:nth-child(2){
            width: 70%;
            height: 500px;
            object-fit: cover;
            border-radius: 10px;
            margin: 0 0 15px 0;
        }
    }
    .photo__container2{
        display: flex;
        flex-direction: row;
        img:nth-child(1){
            width: 70%;
            object-fit: cover;
            border-radius: 10px;
            margin: 0 15px 15px 0;
        }
        img:nth-child(2){
            width: 30%;
            height: 500px;
            object-fit: cover;
            border-radius: 10px;
            margin: 0 0 15px 0;
        }
    }
    .gallary__row{
        flex-direction: row;
        display: flex;
        text-align: left;
        padding: 0 0 20px 0;
        border-bottom: 1px solid #ececec;
        .gallary__col{
            display: flex;
            flex-direction: column;
            width: 50%;
            .gallary__text{
                margin: calcFluid(18, 40, 320, 1920) 0;
                font-size:calcFluid(18, 55, 320, 1920);
            }
            .gallary__arrow{
                display: flex;
                flex-direction: row;
                button{
                    @extend %more;
                    margin: 90px 20px 0 0
                }
            }
        }
        .gallary__main__img{
            margin: 0 0 0 auto;
            width: 50%;
            aspect-ratio: 16/9;
        }
    }
    
    .gallary__item{
        @extend %d-row;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: left;
        .card{
            width: calcFluid(250, 400, 630, 1920);  
            @extend %card;
            margin: 5px;
            
        }
    }
    .bag__clear{
        margin: 150px 0;
        h2, p{
           text-align: center;
        }
        h2{
            font-size: calcFluid(18, 30, 320, 1920);
        }
        p{
            font-size: calcFluid(15, 18, 320, 1920);
        }
    }
}

@media(max-width: 1250px){
    .gallary{
        .gallary__row .gallary__col{
            .gallary__arrow{
                button{
                    margin: 0 20px 0 0;
                }
            }
        }
        .gallary__choose{
            flex-direction: column;
            justify-content: left;
            text-align: center;
            margin: 10px 0 0 0;
            .choose__col{
                button{
                    
                    width: 100%;
                    &:nth-child(1){
                        margin: calcFluid(5, 10, 630, 1920) calcFluid(5, 10, 630, 1920) calcFluid(5, 10, 630, 1920) 0;
                    }
                    &:nth-child(7){
                        margin: calcFluid(5, 10, 630, 1920);
                    }
                  margin: calcFluid(5, 10, 630, 1920);
                }
            }
        }
    }
}

@media(max-width: 1000px){
    .gallary{
        .custom__row{
            .custom__form{
                display: flex;
                flex-direction: column;
                margin: calcFluid(18, 30, 320, 1920) 0 0 0;
                width: 100%;
            }
        }
        .transpor__row{
            display: flex;
            flex-direction: column;
            .transpor__form{
                margin: 0;
            }
            .transpor__col{
                display: flex;
                flex-direction: row;
                .transpor__container{
                    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.20);
                    background-color: white;
                    padding: calcFluid(5, 10, 320, 1920);
                    border-radius: 6px;
                    margin: 0 calcFluid(5, 10, 320, 1920);
                }
            }
            .transpor__photo{
                margin: 0 10px;
                img{
                    object-fit: cover;
                    width: auto;
                }
            }
        }
        .photo__container1{
            display: flex;
            flex-direction: column;
            img:nth-child(1){
                width: 100%;
                object-fit: cover;
                border-radius: 10px;
                height: 500px;
                margin: 0 15px 15px 0;
            }
            img:nth-child(2){
                width: 100%;
                height: 500px;
                object-fit: cover;
                border-radius: 10px;
                margin: 0 0 15px 0;
            }
        }
        .photo__container2{
            display: flex;
            flex-direction: column;
            img:nth-child(1){
                width: 100%;
                height: 500px;
                object-fit: cover;
                border-radius: 10px;
                margin: 0 15px 15px 0;
            }
            img:nth-child(2){
                width: 100%;
                height: 500px;
                object-fit: cover;
                border-radius: 10px;
                margin: 0 0 15px 0;
            }
        }
    }
}


@media(max-width: 630px){
    .gallary{
        .gallary__item{
            .card{
                margin: 4px;    
                width: calcFluid(134, 249, 320, 630);  
            }
        }
    }
    main{
        .gallary{
            .containerLoadingUser{
                .cardLoadingUser{
                    width: calcFluid(126, 243, 320, 630);
                }
            }
        }
    }
}

@media(max-width: 430px){
    .gallary{
        .gallary__item{
            .card{
                margin: 4px;    
                width: calcFluid(134, 249, 320, 630);  
            }
        }
        .complect__row{
            .complect__block{
                width: 40%;
                margin: 10px auto;
                text-align: center;
                p{
                    margin: 0;
                }
                img{
                    margin: 0 auto 10px auto;
                    width: 70px;
                }
            }
            p{
                font-size:calcFluid(12, 16, 320, 1920);
                margin: auto 10px;
            }
        }
        .transpor__row{
            .transpor__col{
                display: flex;
                flex-direction: column;
                .transpor__container{
                    margin: 10px 0;
                }
            }
        }
    }
    main{
        .gallary{
            .containerLoadingUser{
                .cardLoadingUser{
                    width: calcFluid(126, 243, 320, 630);
                }
            }
        }
    }
}

