$gradient: linear-gradient(90deg, #5134c4, #773cbc, #b856d4);
$gray: #ececec;

@function calcFluid($f-min, $f-max, $w-min, $w-max) {
	$k: ($f-max - $f-min)/($w-max - $w-min);
	$b: $f-min - $k * $w-min;
  
	$b: $b * 1px;
  
	@return calc( #{$k} * 100vw + #{$b} );
}
img {
    max-width: 100%;
    height: auto;
}
body{
    padding: 0;
    margin: 0;
    font-family: "Inter-Regular";
}
%main {
    padding: 0 calcFluid(0, 100, 1024, 1920);
    @extend %d-col;
}

%d-row{
    display: flex;
    flex-direction: row;
}

%d-col{
    display: flex;
    flex-direction: column;
}
%spec__row{
    display: flex;
    flex-direction: row;
    .spec__left{
        width: 30%;
        flex-direction: column;
        display: flex;
        border-top: 1px  solid gray; 
        border-left: 1px  solid gray; 
        white-space: nowrap; 
        overflow: hidden;
        -webkit-overflow: hidden;
        text-overflow: ellipsis;
        -webkit-text-overflow: ellipsis;
    }
    .spec__text{
        p{
            font-size: calcFluid(12, 16, 320, 1920);
            font-family: 'inter-light';
            margin: 12px 0 10px 10px;
        }
        border-bottom: 1px solid gray;
    }
    .spec__right{
        width: 70%;
        display: flex;
        flex-direction: column;
        border: 1px  solid gray; 
        border-bottom: 0px solid gray;
        p{
            font-family: 'inter-regular';
            white-space: nowrap; 
            overflow: hidden;
            -webkit-overflow: hidden;
            text-overflow: ellipsis;
            -webkit-text-overflow: ellipsis;
        }
    }
}
%more{
    cursor: pointer;
    padding:calcFluid(9, 11, 320, 1920) calcFluid(15, 20, 320, 800);
    color: white;
    font-size:calcFluid(13, 20, 320, 1920);
    background: $gradient;
    text-decoration: none;
    margin: 20px 0 auto auto;
    border: none;
    font-family: 'inter-regular';
    transition: 0.3s;
    background-size: 150% 100%;
    border: none;
    transition: all .4s ease-in-out;
    border-radius: 5px;
    &:hover {
        background-position: 100% 0;
    }
    
    &:focus {
        outline: none;
    }
}

%slider{
    overflow: hidden;
   @extend %d-row;
    .furniture-line,
    .katalog-line,
    .slider-line,
    .block-line{
        position: relative;
        @extend %d-row;
        width: 200%;
        left: 0;
        transition: all ease 0.7s;
    }
}
%sliderBtn{
    border: 1.5px solid !important;
    border-image-source:$gradient !important;
    border-image-slice:1 !important;
    background:$gradient; 
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'inter-bold';
    transition: 0.3s;
}
%general__log{
    text-decoration: none;
    color: black ;
    padding: calcFluid(7, 10, 320, 1920) 20px;
    font-size:calcFluid(15, 20, 320, 1920);
    text-align: center;
    border: 1px solid black;
    font-family: 'inter-regular';
    margin: auto 4px;
    transition: 0.4s;
}

%general__logo{
    @extend %d-col;
    text-decoration: none;
    background:$gradient;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #5134c4;
    font-family: "fouore";
    font-size:calcFluid(30, 45, 320, 1920);
    span{
        -webkit-text-fill-color: black;
        color: black;
        font-size: calcFluid(12, 18, 320, 1920);;
    }
}

%iconMenu{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    width:  calcFluid(17, 25, 320, 1920);
    height: calcFluid(12, 15, 320, 1920);
    margin: auto 0 auto 10px;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    span{
        margin: auto 0;
        border: 1px solid white;
    }
}

%card {
    position: relative;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.20);
    @extend %d-col;
    background-color: white;
    width: calcFluid(200, 280, 320, 1920);
    padding: calcFluid(5, 10, 320, 1920);
    text-decoration: none;
    color: black;
    border-radius: 6px;
    .radius {
        align-items:center ;
        justify-content: center;
        background-color: #ececec;
        padding: calcFluid(5, 10, 320, 1920);
        height: calcFluid(140, 200, 320, 1920);
        border-radius: 6px;
        margin: 5px 0;
        display: flex;
        .img__emptyPhoto{
            margin: auto;
        }
    }
    .rent{
        font-size: calcFluid(12, 20, 320, 1920);
        color: #58585e;
        font-family: 'inter-regular';
        margin: auto 0 5px 0;
    }
    .info{
        font-size:calcFluid(15, 20, 320, 1920);
        font-family: 'inter-bold';
        margin: 0;
        text-decoration: none;
        color: black;
        cursor: pointer;
    }
    .card__bag{
        cursor: pointer;
        margin: auto 0 0 auto;
        padding: calcFluid(4, 5, 320, 1920) calcFluid(15, 25, 320, 1920);
        background:$gradient;
        background-size: 200% 100%;
        border: none;
        border-radius: 4px;
        transition: all .4s ease-in-out;
        width: calcFluid(20, 30, 320, 1920);
        &:hover {
            background-position: 100% 0;
        }
        
        &:focus {
            outline: none;
        }
        &:active {
            transform: scale(0.80);
        }
    }
    .card__bagActive{
        border-radius: 2px;
        background:transparent;
        border: 1px solid;
        border-image-source:linear-gradient(90deg, #5134c4,#773cbc,#b856d4);
        border-image-slice:1;
        background: transparent;
        text-decoration: none;
        color: black;
    }
    .LinkImg{  
        display: flex;
        width: 100%;
        height: 100%;
        cursor: pointer;
        .card__img{
            display: flex;
            margin: auto;
            img{
                max-width: auto;
                max-height: calcFluid(140, 200, 320, 1920);
            }
           
        }
        display: flex; 
    }
    .fd-row{
        @extend %d-row;
        .card__arrow{
            margin:auto 0 auto auto;
            font-size: calcFluid(18, 24, 320, 1920);
            font-family: 'inter-bold';
            text-decoration: none;
            color: black;
            cursor: pointer;
        }
        .star{
            font-size:  calcFluid(12, 20, 320, 1920);
            font-family: 'inter-bold';
            margin: auto 0;
        }
    }
    .card__sale{
        @extend %d-row;
        margin: 10px 0 0 0;
        .fd-col{
            display: flex;
            flex-direction: column !important;
            .discount{
                text-decoration:line-through;
                color: #58585e;
                font-family: 'inter-regular';
                font-size:calcFluid(12, 20, 320, 1920);
                margin:calcFluid(0, 10, 850, 1920) 0 0 0;
            }
            .card__price{
                font-size: calcFluid(13, 20, 320, 1920);
                font-family: 'inter-bold';
                margin: 0;
            }
        }
    }   
    .furniture__CardActive{
        @extend %d-row;
        border-radius: 20px;
        width: 65%;
        background-color: #5134c4;
        position: absolute; 
        bottom: -18px;
        right: 10px;
        .furniture__bagActive{
            margin: auto 0 auto auto;
            background: white !important;
            border-radius: 50%;
            padding: calcFluid(1, 5, 320, 1920) calcFluid(6, 10, 320, 1920);
            border:2px solid #5134c4;
            font-size:20px;
        }
        p{  
            text-align: center;
            margin: auto 0 auto auto;
            color: white;
            font-size:calcFluid(10, 16, 320, 1920) ;
        }
    }
}
%search{
    .search {
        -webkit-appearance: none;
        box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.20);
        -webkit-box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.20);
        border-radius: 4px;
        border: none;
        padding: 12px calcFluid(10, 50, 1024, 1920) 12px 6px;
        background-color: white;
        color: #6d6d6d;
        font-family: 'inter-light';
        font-size: 20px;
        outline: none;
        width: calcFluid(200, 300, 1024, 1920);
    }
    .search__block{
        height: 192px;
        overflow-y: scroll;
        z-index: 20;
        position: absolute;
        @extend %d-col;
        top: 50px;
        width: 100%;
        background-color: white;
        box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
        a{
            text-decoration: none;
            color: black;
            font-size: calcFluid(13, 15, 1024, 1920);
            padding: 7px 10px;
            font-family: 'inter-light';
            background-color: white;
            &:hover{
                background-color: #eeeeee;
            }
        }
    }
    img {
        position: absolute;
        right: 10px;
        top: 12px;
        max-width: 100%;
        max-height: 100%;
    }
}
@media(max-width: 630px){
    main{
        margin-bottom: 150px;
    }
}