@import "../../widgits/adminScss/layOut.scss";
.login{
    @extend %d-col;
    padding: 0;
    h1{
        margin: 0 0 15px 0;
        font-size:calcFluid(20, 35, 320, 1920);
    }
    &-row{
        @extend %d-col;
        input{
            padding: 15px 10px;
            -webkit-appearance: none;
            margin: 5px 0;
            outline:none;
            border: none;
            -webkit-box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
            font-size:calcFluid(13, 20, 320, 1920);
        }
        .loginBTN{
            @extend %more;
            margin:10px 0;
            text-align: center;
        }
        .loading{
           background: none;
           background-color: rgb(165, 165, 165);
        }
        .error{
            font-size: calcFluid(12, 18, 320, 1920);
            color: red;
            margin: 10px 0 0 0;
        }
    }
}

.logo{
    display: flex;
    flex-direction: row;
    margin: 0 auto 10px auto;
    img{
        margin: calcFluid(7, 23, 320, 1920) 0 auto auto;
        height: auto;
        width: calcFluid(60, 134, 320, 1920);
    }
    a{  
        margin: 0 auto 20px 10px;
        display: flex;
        flex-direction: column;
        text-decoration: none;
        background:$gradient;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: "fouore";
        font-size:calcFluid(35, 90, 320, 1920);
        span{
            -webkit-text-fill-color: black;
            color: black;
            font-family: "fouore";
            font-size: calcFluid(20, 40, 320, 1920);
        }
    }
}