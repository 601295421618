@import "../../widgits/css/general.scss";

main {
    @extend %main;
}

.main_fixed {
    margin-top: 90px
}

.bag__wrapper {
    padding: 0 calcFluid(8, 200, 320, 1920);
    @extend %d-col;
    #error{
        color: red;
        font-size: calcFluid(13, 18, 320, 1920);
    }
    span a {
        text-decoration: none;
        color: black;
        font-size: calcFluid(12, 20, 320, 1920);
    }

    h1 {
        font-size: calcFluid(20, 35, 320, 1920);
    }
    .bag {
        @extend %d-row;
        margin: 0 0 20px 0;
        .bag__items::-webkit-scrollbar {
            display: none;
        }
        &__items {
            @extend %d-col;
            overflow: scroll;
            height: calcFluid(250, 547, 320, 1920);
            width: 100%;
            .bag__clear{
                margin: auto;
                h2, p{
                   text-align: center;
                }
                h2{
                    font-size: calcFluid(18, 30, 320, 1920);
                }
                p{
                    font-size: calcFluid(15, 18, 320, 1920);
                }
            }

            .bag__block {
                border-radius: 8px;
                width: 95%;
                margin: 5px 0;
                @extend %d-row;
                box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.20);
                padding: 10px;
                text-decoration: none;
                margin: 5px 0;
                .block__radius{
                    width: 30%;
                    align-items:center ;
                    justify-content: center;
                    background-color: #ececec;
                    padding: calcFluid(5, 10, 320, 1920);
                    border-radius: 6px;
                    margin: 0;
                    display: flex;
                    .img__emptyPhoto{
                        margin: auto;
                    }
                    .block__img {
                        img{
                            max-width: auto;
                            max-height: calcFluid(120, 145, 320, 1920);
                            margin: 0;
                        }
                    }

                }
                .block__content {
                    position: relative;
                    margin: 0 0 0 10px;
                    width: 70%;
                    @extend %d-col;
                    color: black;

                    .block__close {
                        right: 0;
                        top: -15px;
                        cursor: pointer;
                        font-size: calcFluid(18, 25, 320, 1920);
                        font-family: "inter-bold";
                        color: #58585e;
                        position: absolute;
                    }

                    .rent {
                        color: #58585e;
                        font-family: "inter-regular";
                        margin: calcFluid(0, 20, 320, 1920) 0 0 0;
                    }

                    p,a {
                        text-decoration: none;
                        color: black;
                        font-size: calcFluid(20, 20, 320, 1920);
                        font-family: "inter-bold";
                        margin: 10px 0;
                    }

                    .block__input {
                        margin: auto 0 0px 0;
                        @extend %d-row;
                        .quantity_innerContainer{
                            display: flex;
                            flex-direction: row;
                        }
                        .quantity_inner {
                            display: flex;
                            flex-direction: row;
                            margin: auto 10px auto 0;
                            padding: 0 5px;
                            border-radius: 5px;
                            text-align: center;
                            align-items: center;
                            border: 0.6mm solid #D3D3D3;
                            .bt_minus,
                            .bt_plus,
                            .bt_minusMonth,
                            .bt_plusMonth,
                            .quantity {
                                user-select: none;
                                padding: 0;
                                border: 0;
                                margin: 0;
                                background: transparent;
                                cursor: pointer;
                                outline: 0;
                                font-family: "inter-regular";
                                font-size: calcFluid(20, 35, 320, 1920);
                            }

                            .bt_plus,
                            .bt_plusMonth {
                                font-size: calcFluid(30, 35, 320, 1920);
                                background: $gradient;
                                -webkit-background-clip: text;
                                background-clip: text;
                                -webkit-text-fill-color: transparent;
                                &:active{
                                    transform: scale(0.80);
                                }
                            }
                            .bt_minus,
                            .bt_minusMonth {
                                font-size: calcFluid(30, 35, 320, 1920);
                                &:active{
                                    transform: scale(0.80);
                                }
                            }

                            .fd-col {
                                @extend %d-col;
                                margin: 0;
                                font-size: calcFluid(9, 13, 320, 1920);
                                text-align: center;
                                .quantity {
                                    width: 50px;
                                    text-align: center;
                                    font-size: calcFluid(17, 25, 320, 1920);
                                    font-weight: bold;
                                    color: #000;
                                    margin: 0;
                                    width: 100%;
                                }  
                            }
                        }
                        .block-col {
                            margin: auto 0 0 auto;

                            .block__price,
                            .block__discount {
                                margin: 5px 0 0 0;
                            }

                            .block__discount {
                                font-size: calcFluid(10, 18, 320, 1920);
                                text-decoration: line-through;
                                color: #58585e;
                            }
                        }
                    }
                }
            }
        }
        .clear{
            height: calcFluid(150, 300, 320, 1920);
        }
        .bag__price {
            width: 24%;
            @extend %d-col;
            .accept{
                font-family: "inter-regular";
                color: black;
                font-size: calcFluid(12, 16, 800, 1920);
                margin: 18px 0 0 0;
            }
            .line{
                border: 1px solid #BABABA;
                margin: 20px 0;
            }
            .bag__promoCode {
                @extend %d-row;
                input {
                    border-radius: 4px;
                    font-size: calcFluid(12, 20, 800, 1920);
                    font-family: "inter-light";
                    padding: 7px;
                    outline: none;
                    border: 2px solid #BABABA;
                    margin: auto 10px auto 0;
                }

                #acceptPromocode {
                    border-radius: 4px;
                    background-color: #19103C;
                    padding: 7px;
                    font-size: calcFluid(10, 20, 320, 1920);
                    color: white;
                    font-family: "inter-light";
                    border: none;
                    cursor: pointer;
                }
            }
            .input__price {
                @extend %d-row;
                margin: calcFluid(2, 20, 320, 1920) 0 calcFluid(2, 10, 320, 1920) 0;
                font-size: calcFluid(18, 25, 320, 1920);
                font-family: "inter-bold";
                p {
                    margin: 0;
                }
                #price {
                    font-size: calcFluid(23, 30, 320, 1920);
                    margin: 0 0 0 auto;
                    font-family: "inter-bold";
                }                
            }
            span {
                font-size: calcFluid(13, 20, 320, 1920);
                font-family: "inter-bold";
            }
            .input__discount{
                margin: 0 0 calcFluid(0, 20, 320, 1920) 0;
                @extend %d-row;
                font-family: "inter-bold";
                p {
                    font-size: calcFluid(16, 22, 320, 1920);
                    margin: 0;
                }
                #discount {
                    margin: 0 0 0 auto;
                    font-size: calcFluid(16, 25, 320, 1920);
                    color: #f91155;
                }
            }

            .delivery,
            .self-call {
                margin: 10px 0;
                @extend %d-row;
                font-size: calcFluid(13, 20, 320, 1920);
            }

            input[type='checkbox'] {
                margin: auto 10px auto 0;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                display: inline-block;
                width: 1.5em;
                height: 1.5em;
                border: 1px solid #adb5bd;
                cursor: pointer;
                vertical-align: bottom;

                &:checked {
                    background: $gradient;
                }
            }

            input[type="checkbox"]:after {
                content: ' ';
                position: relative;
                left: 35%;
                top: 10%;
                width: 15%;
                height: 50%;
                border: solid #fff;
                border-width: 0 3px 3px 0;
                transform: rotate(50deg);
                display: none;
            }

            input[type="checkbox"]:checked:after {
                display: block;
            }

            input[type="tel"],
            #adress,
            #nameOrder {
                margin: 10px auto 0 0;
                padding: 5px 10px;
                outline: none;
                border: 1px solid #58585e;
                width: 60%;
                font-size: calcFluid(12, 18, 800, 1920);
                font-family: "inter-light";
            }
            #order {
                @extend %more;
                margin: 10px 0 auto 0;
            }
        }
    }
}

@media(max-width: 1060px) {
    .bag__wrapper .bag {
        flex-direction: column;

        &__items {
            width: 100%;
            margin: 0 0 calcFluid(20, 50, 320, 1060) 0;
        }

        .bag__price {
            width: 100%;

            span {
                margin: 0 0 0 auto !important;
            }

            #order {
                width: 100%;
            }
            .bag__promoCode {
                display: flex;
                input {
                    width: 90%;
                    font-size: calcFluid(14, 20, 320, 1920);
                }
                #acceptPromocode {
                    font-size: calcFluid(14, 20, 320, 1920);
                }
            }
        }
        .bag__block {
            width: 91%;
        }
    }

}
@media(max-width: 630px) {
    .bag__wrapper{
    .bag {
        &__items {
            height: 100%;
            overflow: visible;
            margin: 0;
            .bag__clear{
                margin: 200px 0;
            }
            .bag__block {
                width: auto;
                .block__content {
                    .rent {
                        font-size: calcFluid(13, 16, 320, 630);
                    }
                    a {
                        font-size: calcFluid(17, 20, 320, 1920);
                        margin: calcFluid(6, 10, 320, 630) 0;
                    }
                    .block__input {
                        .quantity_inner {
                            .bt_minus,
                            .bt_minusMonth {
                                margin: 0 calcFluid(1, 5, 320, 630);
                            }
                        }
                        .block-col {
                            .block__price,
                            .block__discount {
                                margin:  0;
                                font-size: calcFluid(15, 17, 320, 630);
                            }

                            .block__discount {
                                text-align: end;
                                margin: 0 0 5px 0;
                                font-size: calcFluid(13, 16, 320, 630);
                            }
                        }
                    }
                }
            }
        }
        
    }
    }
}

@media(max-width: 540px) {
    .bag__wrapper{
        .bag {
            &__items {
                .bag__block {
                    .block__content .block__input{
                        flex-direction: column-reverse;
                        .block-col{
                            margin: 0 0 5px 0;
                            .block__discount{
                                text-align: start;
                            }
                        }
                    }
                }
            }
        }
    }
}