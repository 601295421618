@import "../../widgits/css/general.scss";
main{
    @extend %main;
}
.main_fixed{
    margin-top: 90px
}

.katalog{
    padding: 0 calcFluid(8, 200, 320, 1920);
    @extend %d-col;
    .bag__clear{
        margin:150px auto auto auto;
        h2, p{
           text-align: center;
        }
        h2{
            font-size: calcFluid(18, 30, 320, 1920);
        }
        p{
            font-size: calcFluid(15, 18, 320, 1920);
        }
    }
    
    span a{
        text-decoration: none;
        color: black;
        font-size: calcFluid(12, 20, 320, 1920);
    }
    h1{
        font-size:calcFluid(20, 35, 320, 1920);
    }
    &-row{
        @extend %d-row;
        p, label{
            font-family: "inter-bold";
            font-size: calcFluid(12, 18, 320, 1920);
            span{
                background:$gradient;
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: transparent;
                color: #5134c4;
            }
        }
        .firstFilter{
            @extend %d-row;
            font-size: calcFluid(13, 18, 320, 1920);
            text-align: center;
            align-items: center;
        }
        #firstFilter{
            position: relative;
            @extend %d-col;
            margin: 0;
            background:$gradient;
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            color: #5134c4;
            border: none;
            outline: none;
            appearance: none;
            .active{
                font-size: calcFluid(13, 18, 320, 1920);
                cursor: pointer;
                margin: 0;
            }
            .select{
                position: absolute;
                z-index: 100;
                top: 25px;
                background-color: white;
                @extend %d-col;
                box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.20);
                p{  
                    cursor: pointer;
                   padding: 5px ;
                   margin: 0;
                    box-sizing: border-box;
                    -webkit-text-fill-color: black;
                    &:hover{
                        background-color: #e7e7e7;
                    }
                }
            }
        }
        .filter__open{
            cursor: pointer;
            display: none;
            font-size: 20px;
            @extend %d-row;
            margin: auto 0px auto auto;
            img{
                margin: auto 10px;
                width: calcFluid(22, 35, 320, 1920);
            }
        }
        .filter__close{
            text-align: center;
            align-items: center;
            display: none;
            font-size: calcFluid(28, 30, 320, 1920);
            margin: auto 0px auto auto;
            font-family: 'inter-regular';
            color: black;
        }
        .katalog__kol{
            margin: auto 0 auto 50px;
        }
        .katalog__filter{
            padding: 10px;
            width: 20%;
            box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.25);
            h2{
                font-size: 20px;
                margin: 10px 0;
            }
            .label__price{
                font-family: 'inter-regular';
                text-align: center;
                align-items: center;
                user-select: none;
                width: 50%;
                @extend %d-row;
                padding: 6px 10px;
                border: 1px solid #A9A9A9;
                margin: auto 10px auto 0;
                color:#A9A9A9 ;
                .katalog__price, p{
                    padding: 0 0 0 5px ;
                    width: 100%;
                    border: none;
                    outline:none;
                    font-size: 18px;
                }
                p{  
                    color:  black;
                    margin: 0;
                }
            }
            .katalog-name,
            .katalog-size,
            .katalog-star {
                @extend %d-col;
                label{
                    width: 100%;
                    font-size: calcFluid(15, 16, 320, 1920);
                    margin: 7px 0;
                }
            }
            .katalog-star{
                label{font-size: calcFluid(15, 18, 320, 1920);}
            }

            .container{
                position: relative;
                width: 100%;
                height: 60px;
                input[type="range"]{
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    width: 100%;
                    outline: none;
                    position: absolute;
                    margin: auto;
                    top: 0;
                    bottom: 0;
                    background-color: transparent;
                    pointer-events: none;
                }
                .slider-track{
                    width: 100%;
                    height: 5px;
                    position: absolute;
                    margin: auto;
                    top: 0;
                    bottom: 0;
                    border-radius: 5px;
                }
                input[type="range"]::-webkit-slider-runnable-track{
                    -webkit-appearance: none;
                    height: 5px;
                }
                input[type="range"]::-webkit-slider-thumb{
                    -webkit-appearance: none;
                    height: 1.7em;
                    width: 1.7em;
                    background: $gradient;
                    cursor: pointer;
                    margin-top: -9px;
                    pointer-events: auto;
                    border-radius: 50%;
                }
            }
            input[type='checkbox'] {
                margin: auto 10px auto 0;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                display: inline-block;
                width: 1.5em;
                height:1.5em;
                border: 1px solid #adb5bd;
                cursor: pointer;
                vertical-align:bottom;
                &:checked{
                   background: $gradient;
                }
            }
              
            input[type="checkbox"]:after {
            content: ' ';
            position: relative;
            left: 35%;
            top: 10%;
            width: 15%;
            height: 50%;
            border: solid #fff;
            border-width: 0 3px 3px 0;
            transform: rotate(50deg);
            display: none;
            }
            
            input[type="checkbox"]:checked:after {
            display: block;
            }
            #clear{
                @extend %more;
                margin: 10px 0 15px 0;
                width: 99%;
            }

        }
        .katalog-line{
            overflow: scroll;
            width: 80%;
            height: 900px;
            @extend %d-row;
            flex-wrap: wrap;
            align-content: flex-start;
            .card{
                width: calcFluid(98, 304, 320, 1920);
                margin: 7px;
                @extend %card;
            }
        }
        .katalog-line::-webkit-scrollbar {
            display: none;
        }
    }
}
@media(max-width: 1024px){
    .katalog{
        .katalog__filter{
            background-color: white;
            z-index: 10;
            transition: all 300ms;
            top: 0;
            right: 0;
            position: fixed;
            width: 62%;
            height: 100%;
        }
        .filter__open,
        .filter__close{
            display: flex;
        }
        .katalog-line{
            width: 100%;
            .card{
                width:calcFluid(68, 245, 320, 1024)  !important;
            }
            
        }
    }
}
@media(max-width: 730px){
    .katalog{
        .katalog-line{
            overflow: visible;
            width: auto;
            height: auto;
            .card{
                width:calcFluid(128, 288, 320, 730) !important;
                margin: 3px;
            }
        }
    }
}

@media(max-width: 630px){
    .katalog{
        .katalog__filter{
            z-index: 2000000;
            width: calcFluid(300, 610, 320, 630);
            height: 100%;
            overflow: scroll;
        }
        .katalog-row{
            margin: 0;
        }
        .katalog-line{
            overflow: visible;
            width: auto;
            height: auto;
            .card{
                width:calcFluid(140, 285, 320, 630) !important;
                margin: 3px;
            }
        }
    }
}


@media(max-width: 630px){
    main{
        .katalog {
            padding: 0 calcFluid(2, 10, 320, 630);
            .katalog-row{
                .containerLoadingUser{
                    .cardLoadingUser{
                        width: calcFluid(126, 243, 320, 630);
                    }
                }
            }
        }
    }
}