@import "../../widgits/css/general.scss";
main{
    @extend %main;
}
.main_fixed{
    margin-top: 90px
}
.map{
    span a{
        text-decoration: none;
        color: black;
        font-size: calcFluid(12, 20, 320, 1920);
    }
    padding: 0 calcFluid(8, 200, 320, 1920);
    @extend %d-col;
    h1{
        font-family: "inter-bold";
        font-size:calcFluid(17, 30, 320, 1920) ;
    }
    &__contact{
        @extend %d-row;
        position: relative;
        .map-col{
            margin: 0 auto 0 0;
            @extend %d-col;
            h2{
                font-size: calcFluid(13, 18, 320, 1920);
                font-family: "inter-regular";
                opacity: 0.4;
            }
            p{
                margin: 0;
                font-size: calcFluid(15, 20, 320, 1920);
                font-family: "inter-regular";
            }
        }
    }
    #map__yandex{
        margin: 20px 0 ;
        width: 100%;
        height: 350px;
    }
    [class*="copyrights-pane"]{
        display: none !important;
    }
}

@media(max-width: 630px){
    .map {
        &__contact{
            margin: 49px 0 0 0 ;
            .map-col{
                text-align: center;
                margin: auto;
               &:nth-child(2){
                position: absolute;
                top: -60px;
                left:  calcFluid(50, 150, 320, 630);
               }
            }
        }
        &__contact:nth-child(5){
            margin: 0px 0 0 0 ;
        flex-direction: column;
        display: flex;
        position: relative;
        .map-col{
            margin: 0 0 0 0;
            display: flex;
            flex-direction: column;
            text-align: center;
            justify-content: center;

            h2{
                font-size: calcFluid(12, 18, 320, 1920);
                font-family: "inter-regular";
                opacity: 0.4;
            }
            p{
                margin: 0;
                font-size: calcFluid(15, 20, 320, 1920);
                font-family: "inter-regular";
            }
            &:nth-child(2){
                position: relative;
                top: 0px;
                left: 0;
               }
        }
        }
       
    }
}