@import "./general.scss";
@font-face {
    font-family: "inter-light";
    font-style: normal;
    font-weight: 100;
    src: url("../../assets/fonts/Inter/Inter-Light.ttf");
    font-display: swap;
}
@font-face {
    font-style: normal;
    font-family: "inter-regular";
    font-weight: 500;
    src: url("../../assets/fonts/Inter/Inter-Regular.ttf");
    font-display: swap;
}
@font-face {
    font-style: normal;
    font-family: "inter-bold";
    font-weight: 900;
    src: url("../../assets/fonts/Inter/Inter-Bold.ttf");
    font-display: swap;
}
@font-face {
    font-style: normal;
    font-family: "fouore";
    src: url("../../assets/fonts/fouore/Furore.otf");
    font-display: swap;
}
.pd-100{
    padding: 0 calcFluid(0, 100, 1024, 1920);
    h2{
        font-size: calcFluid(17, 25, 320, 1920);
        font-family: "inter-bold";
        padding:0 calcFluid(5, 200, 320, 1920);
    }
}
.answer{
    padding:0 calcFluid(5, 200, 320, 1920);
    margin: 10px 0;
    @extend %d-col;
    &__block{
        box-shadow: 4px 2px 20px rgba(0, 0, 0, 0.25); 
        @extend %d-col;
        padding: 6px;
        .answer__question{
            @extend %d-row;
            .answer__info{
                color: white;
                font-size:calcFluid(22, 27, 320, 1920);
                font-family: "inter-bold";
                background: $gradient;
                padding:2px 14px;
                margin: auto 0;
            }
            p{
                font-size: calcFluid(15, 18, 320, 1920);
                font-family: "inter-regular";
                margin: auto 0 auto 20px;
                
            }
            .answer__open{
                user-select: none;
                background: $gradient;
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: transparent;
                color: #5134c4;
                font-family: "inter-bold";
                font-size: 30px;
                margin: 0 0 0 auto;
                transition:0.3s ease-out;
                cursor: pointer;
                transform: rotate(90deg);
            }
            .active{
                transform: rotate(270deg);
            }
        }
    }
    &__content{
        transition:0.3s ease-out ;
        overflow: hidden;
        max-height: 0;
        font-size: 15px;
        font-family: "inter-regular";
    }
    .active{
        max-height: 100%;
        margin: 15px 0;
    }
}


.basement{
    position: relative;
    background-color: #19103C;
    margin-top: 120px;
    @extend %d-col;
    padding:0 calcFluid(0, 100, 1024, 1920);
    &__block{
        box-shadow: 4px 2px 20px rgba(0, 0, 0, 0.25); 
        position: absolute;
        width: calcFluid(320, 1125, 100, 1920);
        top: -90px;
        background: $gradient;
        color: white;
        margin: 0 calcFluid(0, 200, 320, 1920);
        padding:  calcFluid(12, 25, 320, 1920) calcFluid(25, 100, 320, 1920);
        @extend %d-row;
        .basement__col{
            @extend %d-col;
            width: 60%;
            h2{
                margin: 0 0 calcFluid(10, 20, 320, 1920)  0;
                font-size: calcFluid(17, 25, 320, 1920);
            }
            p{  
                width: 90%;
                margin: 0;
                font-size: calcFluid(13, 20, 320, 1920);
            }
        }
        .basement__col{
            .logFooter{
                @extend %d-row;
                color: transparent;
                .login,
                .register,
                .katalog{
                    @extend %general__log;
                    background-color: white;
                    border: none;
                }
                .katalog{
                    width: 100%;
                }
                .register{
                    background: #19103C;
                    color: white;
                    width: 60%;
                }
                .login{
                    width: 40%;
                }
                .loginUser{
                    display: flex;
                    margin: auto 0 ;
                    @extend %d-row;
                    text-decoration: none;
                    color: rgb(255, 255, 255);
                    font-size: calcFluid(16, 22, 320, 1920);
                    p{
                        margin: auto 0;
                        width: 100%;
                    }
                }
            }
            h3{
                font-size: calcFluid(15, 20, 320, 1920);
                margin: 0 0 auto 0;
            }
            h3:last-child{
                margin: auto 0 0 0;
            }
        }
    }
    &__content{
        padding: 0 calcFluid(5, 200, 320, 1920);
        margin: calcFluid(60, 120, 320, 1920)0 40px   0;
        @extend %d-row;
        .d-col{
            margin: 0 calcFluid(70, 130, 1024, 1920) 0 0;
            @extend %d-col;
            .basement__logo{
                @extend %d-col;
                @extend %general__logo; 
                margin: -5px 0 5px  0;
                font-size:calcFluid(30, 40, 320, 1920);
                -webkit-text-fill-color: white;
                span{
                    -webkit-text-fill-color: white;
                }
            }
            .block{
                @extend %d-col;
                .prava{
                    text-decoration: none;
                    color:#8a9dff;
                    margin: 8px 0;
                }
            }
            h4{
                font-size:calcFluid(13, 18, 320, 1920) ;
                font-family: "inter-bold";
                color: white;
                margin: 0 0 calcFluid(0, 20, 320, 1920)  0;
            }
            a,
            p{
                text-decoration: none;
                color: white;
                margin: 7px 0;
                font-size:calcFluid(10, 15, 320, 1920) ;
            }
            &:last-child{
                margin: 0 ;
            }
        }  
    
    }
}
.mobile__bag{
    display: flex;
    flex-direction: row;
    position: fixed;
    background-color: white;
    z-index: 20;
    bottom: calcFluid(80, 88, 320, 630);
    left: 0;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.25);
    text-align: center;
    align-items: center;
    #order {
        @extend %more;
        margin: 17px 17px auto auto;
    }
    p:first-child{
        margin: 17px calcFluid(8, 17, 320, 630) 17px 17px;
        font-family: 'inter-bold';
        font-size: calcFluid(23, 27, 320, 630);
    }
    p:nth-child(2){
        font-family: 'inter-bold';
        margin: 17px 0;
        color: red;
        font-size: calcFluid(18, 23, 320, 630);
    }
}
.mobile{
    display: flex;
    flex-direction: row;
    position: fixed;
    background-color: white;
    z-index: 1000;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: 0.7mm solid;
    border-image-source:  $gradient;
    border-image-slice: 1;
    justify-content: center;
    align-items: center;
    padding: 9px 0 33px 0;
    .mobile__block{
    
        margin: auto;
        display: flex;
        flex-direction: column;
        position: relative;
        a{
            margin: auto;
            .services__sum{
                position: absolute;
                top: -3px;
                right: calcFluid(1, 13, 320, 630);
                color: white;
                width: 14px;
                height: 14px;
                border-radius: 50%;
                background: $gradient;
                text-align:center;
                font-family: "inter-regular";
                p{
                    font-size: 10px;
                    margin: auto;
                }
            }
        }
        .mobile__text{
            margin: 0;
            color: #BABABA;
            font-size: calcFluid(10, 16, 320, 630);
        }
        .active{
            color: #7F24FF;
        }
    }
}
.mobile__login{
    .account{
        text-align: center;
        margin: auto 0 0 0;
    }
    border: none;
}

@media(max-width: 970px){
    .basement{
        &__block{
            width: calcFluid(269.5, 690, 320, 970);
            display: flex;
            flex-direction: column;
            .basement__col:nth-child(1){
                p{display: none;}
            }
            .basement__col{
                width: 100%;
                h3{display: none;}
                text-align: center;
                margin: 0 auto;
            }
        }
    }
}
@media(max-width: 780px){
    .basement{
        .basement__content{
            display: flex;
            flex-direction: column;
            .d-col{
               display: flex;
               flex-direction: row;
                margin: 0;
                .block{
                    margin: 0 0 10px auto;
                    .prava{
                        margin: 4px 0;
                    }
                }
            }
            .d-col:nth-child(2),
            .d-col:nth-child(3){
                a{display: none;}
                padding: 10px;
                border-top: 1px solid white;
                border-bottom: 1px solid white;
                h4{
                    margin: auto 0;
                }
            }
            .d-col:last-child{
                padding: 10px;
                display: flex;
                flex-direction: column;
            }
            .basement__logo{
                margin: 0 0 auto 0;
            }
        }
        &__block{
            margin: 0;
            box-shadow: 0;
            width: calcFluid(288, 587, 340, 670);
            .basement__col{
                .login,
                .register{
                    margin: auto 0;
                }
            }
        }
    }
}

@media(max-width: 630px){
    .katalog__modal{
        z-index: 900;
        top:0;
        .modal__sideBar{
            width: 100%;
            p{
                text-align: center;
            }
            .active{
                border-left:2px solid #A651D4 ;
            }
        }
    }
}