@import "../../widgits/css/general.scss";
main{
    @extend %main;
}
.main_fixed{
    margin-top: 90px
}
.register{
    padding: 0 calcFluid(8, 400, 320, 1920);
    @extend %d-col;
    h1{
        margin: 0 0 15px 0;
        font-size:calcFluid(20, 35, 320, 1920);
    }
    &-row{
        @extend %d-col;
        input{
            border-radius: 6px;
            -webkit-appearance: none;
            padding: 15px 10px;
            margin: 5px 0;
            outline:none;
            border: none;
            -moz-box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.20);
            box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.20);
            -webkit-box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.20);
            font-size:calcFluid(14, 16, 320, 1920);
        }
        label{
            display: flex;
            font-size:calcFluid(12, 16, 320, 1920);
            margin: calcFluid(3, 20, 320, 1920) 0 calcFluid(13, 20, 320, 1920) 0 ;
            align-items: center;
            #accept {
                margin: auto 10px auto 0;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: auto;
                height: calcFluid(25, 30, 320, 1920);
                width: calcFluid(25, 30, 320, 1920);
                border: 1px solid #adb5bd;
                cursor: pointer;
                vertical-align:bottom;
            }
        }
        #register{
            @extend %more;
            margin:10px 0;
            text-align: center;
        }
        .error{
            font-size: calcFluid(12, 18, 320, 1920);
            color: red;
            margin: 10px 0 0 0;
        }
        .registerBTN{
            @extend %more;
            margin:10px 0;
            text-align: center;
        }
        .loading{
           background: rgb(165, 165, 165) !important;
        }
    }
    .account{
        font-size: calcFluid(13, 16, 320, 1920);
        a{
            text-decoration: none;
        }
    }
}
@media(max-width: 630px){
    .register{
        padding: 80px calcFluid(8, 400, 320, 1920);
    } 
}