$gradient: linear-gradient(90deg, #5134c4, #773cbc, #b856d4);
$gray: #ececec;
@font-face {
    font-family: "inter-light";
    font-style: normal;
    font-weight: 100;
    src: url("../../assets/fonts/Inter/Inter-Light.ttf");
    font-display: swap;
}
@font-face {
    font-style: normal;
    font-family: "inter-regular";
    font-weight: 500;
    src: url("../../assets/fonts/Inter/Inter-Regular.ttf");
    font-display: swap;
}
@font-face {
    font-style: normal;
    font-family: "inter-bold";
    font-weight: 900;
    src: url("../../assets/fonts/Inter/Inter-Bold.ttf");
    font-display: swap;
}
@font-face {
    font-style: normal;
    font-family: "fouore";
    src: url("../../assets/fonts/fouore/Furore.otf");
    font-display: swap;
}
@function calcFluid($f-min, $f-max, $w-min, $w-max) {
	$k: ($f-max - $f-min)/($w-max - $w-min);
	$b: $f-min - $k * $w-min;
  
	$b: $b * 1px;
  
	@return calc( #{$k} * 100vw + #{$b} );
}


body{
    font-family: "Inter-Regular";
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    position: relative;
}
.LayOut{
    display: flex;
    flex-direction: row;
    .LayOut__children{
        margin: 0 0 0 20%;
        width: 80%;
        display: flex;
        flex-direction: column;
        padding: 20px 40px 20px 40px;
    }
}

.LayOut__Login{
    display: flex;
    flex-direction: column;
    padding: calcFluid(80, 100, 320, 1920) calcFluid(40, 250, 320, 1920);
}
%more{
    padding:calcFluid(5, 10, 320, 1920) calcFluid(10, 20, 320, 1920);
    color: white;
    font-size:calcFluid(15, 20, 320, 1920);
    background: $gradient;
    text-decoration: none;
    margin: 20px 0 auto auto;
    border: none;
    font-family: "Inter-Regular";
    transition: 0.3s;
    background-size: 150% 100%;
    border: none;
    transition: all .4s ease-in-out;
    &:hover {
        background-position: 100% 0;
    }
    
    &:focus {
        outline: none;
    }
}

%d-row{
    display: flex;
    flex-direction: row;
}

%d-col{
    display: flex;
    flex-direction: column;
}

%bag__block {
    margin: 10px 0;
    display: flex;
    flex-direction: row;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.20);
    padding: 10px;
    text-decoration: none;
    
    .card__img{
        max-height: calcFluid(80, 172, 320, 1920);
    }
    .img__empty{
        display: flex;
        width: calcFluid(161, 322, 320, 1920);
        height: calcFluid(86, 172, 320, 1920);
        background-color: #E3E3E3;
        border-radius: 6px;
        .img__emptyPhoto{
            margin: auto;
        }
    }

    .block__content {
        position: relative;
        margin: 0 0 0 40px;
        width: 80%;
        display: flex;
        flex-direction: column;
        color: black;
        .rent {
            color: #58585e;
            font-family: "Inter-Regular";
            margin: 0 0 auto 0;
            font-size: calcFluid(12, 18, 320, 1920);
            span{
                color: black;
                font-family: "Inter-Bold";
            }
        }

        .orderNumber {
            color: black;
            font-size: calcFluid(15, 22, 320, 1920);
            font-family: "Inter-Bold";
            margin: 10px 0;
        }
        .OrderName {
            text-decoration: none;
            color: black;
            font-size: calcFluid(15, 28, 320, 1920);
            font-family: "Inter-Bold";
            margin: 0;
            width: 100%;
        }
        .track{
            @extend %more;
            padding: calcFluid(2, 10, 320, 1920)  calcFluid(20, 45, 320, 1920);
            margin: 15px auto 0 0;
        }
        .history{
            background: gray;
        }
        .active{
          background: gray;
        }

        .block__inputRow{ 
            margin: auto 0 0 0;
           display: flex;
           flex-direction: row;
        }

        .block__input{
           display: flex;
           flex-direction: column;
            .fd-col{
                margin: 0;
                font-family: "inter-regular";
                color: #58585e;
                font-size: calcFluid(10, 16, 320, 1920);
                span{
                    font-size: calcFluid(12, 20, 320, 1920);
                    color: black;
                }
            }
            .fd-col:nth-child(1){
                margin: auto 0 10px 0;
            }
        }
        .block-col {
            margin: auto 0 0 auto;

            .block__price,
            .block__discount {
                margin: 5px 0 0 0;
                font-family: "Inter-Bold";
            }

            .block__discount {
                text-align: end;
                font-size: calcFluid(14, 20, 320, 1920);
                text-decoration: line-through;
                color: #58585e;
            }
            .block__price{
                font-size: calcFluid(16, 22, 320, 1920);
            }
        }
    }
}
%headerOrder{
    cursor: pointer;
    z-index: 10;
    overflow:hidden ;
    display: flex;
    flex-direction: row;
    a, .btnForCreate {
        cursor: pointer;
        margin: calcFluid(16, 40, 320, 1920) 0 calcFluid(0, 35, 320, 1920) 20px;
        font-size: calcFluid(15, 19, 320, 1920);
        font-family: "Inter-Regular";
        text-decoration: none;
        border-bottom: 2px solid $gray;
        color: black;
        transition: 0.3s;
        height: 100%;
        &:nth-child(1) {
            display: flex;
            flex-direction: row;
            background: $gradient;
            color: white;
            padding: 10px;
            margin: auto 0;
            background-size: 150% 100%;
            border: none;
            transition: all .4s ease-in-out;
            &:hover {
                background-position: 100% 0;
            }
            &:focus {
                outline: none;
            }
        }

        &:last-child {
            display: flex;
            flex-direction: row;
            position: relative;
            margin-left: auto;
            img {
                margin: 0 10px 1px 0;
            }
        }
        &:not(:first-child):hover {
            border-bottom: 2px solid;
            border-image-source:  $gradient;
            border-image-slice: 1;
            margin-top: calcFluid(13, 35, 320, 1920);
            margin-bottom:  calcFluid(15, 40, 320, 1920);
        }
        .services__sum {
            position: absolute;
            top: -15px;
            left: 15px;
            font-size: 14px;
            color: white;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background: $gradient;
            text-align:center;
            font-family: "inter-bold";
            p{
                margin: 0;
            }
        }
    }
}