@import "../../widgits/css/general.scss";
main {
    @extend %main;
    .line{
        width: 100%;
        margin-top: 30px;
        border-bottom: 1px solid #ECECEC;
    }
}
.main_fixed{
    margin-top: 90px
}
.fd-row{
    @extend %d-row;
    .slider {
        margin: 0 auto 0 0;
        padding: 20px 0 20px calcFluid(2, 200, 320, 1920) ;
        width: calcFluid(200, 900, 320, 1920);
        background-color: #0d0337;
        overflow: hidden;
        position: relative;
        display: flex;
        .slides {
            width: 400%;
            display: flex;
            input[name="r"] {
                position: absolute;
                visibility: hidden;
            }
            .slide {
                @extend %d-row;
                margin: 0  calcFluid(10, 350, 320, 1920) 0 0;
                width: 100%;
                transition: all 0.6s ease;
                &__contant {
                    width:calcFluid(120, 230, 320, 1920);
                    @extend %d-col;
                    margin: auto 0
                }
                &__text {
                    @extend %d-col;
                    color: white;
                    h2 {
                        font-family: "inter-bold";
                        font-size: calcFluid(18, 32, 320, 1920);
                        margin: 10px 0;    
                    }
                    p {
                        font-size: calcFluid(12, 20, 320, 1920);
                        margin: 10px 0;
                    }
                }

                &__button {
                    @extend %d-row;
                   margin: 10px 0 30px 0;
                    .more {
                        @extend %more;
                        margin: auto 0;
                    }
                }

                &__radius {
                    display: flex;
                    margin: auto 0 auto calcFluid(10, 100, 320, 1920); 
                    width: calcFluid(100, 500, 320, 1920);
                    height: calcFluid(50, 272, 320, 1920);
                    border-radius: 50%;
                    box-shadow: 10px 10px 20px rgba(255, 255, 255, 0.25);
                    background-color: white;
                    img{
                        width: 90%;
                        margin: auto;
                    }
                }
            }
            .navigation-auto {
                display: flex;
                position: absolute;
                bottom: 10px;
            }
            .navigation-auto div {
                height: calcFluid(12, 15, 320, 1920);
                width: calcFluid(12, 15, 320, 1920);
                border-radius: 50%;
                border: 1px solid white;
                cursor: pointer;
                transition: all 0.4s ease;
                margin: calcFluid(5, 10, 320, 1920);
                &:first-child{
                    margin-left: 0;
                }
            }
            #r1:checked ~ .navigation-auto .auto-btn1,
            #r2:checked ~ .navigation-auto .auto-btn2,
            #r3:checked ~ .navigation-auto .auto-btn3,
            #r4:checked ~ .navigation-auto .auto-btn4 {
                background: $gradient;
            }
        }
        .navigation {
            display: flex;
            position: absolute;
            bottom: 10px;
        
            &__bar {
                height:calcFluid(12, 15, 320, 1920);
                width: calcFluid(12, 15, 320, 1920);
                border-radius: 50%;
                border: 1px solid white;
                cursor: pointer;
                transition: all 0.4s ease;
                margin: calcFluid(5, 10, 320, 1920);
                &:first-child{
                    margin-left: 0;
                }
            }
        }
        #r1:checked ~ .s1 {
            margin-left: 0;
        }
        #r2:checked ~ .s1 {
            margin-left: -33.5%;
        }
        #r3:checked ~ .s1 {
            margin-left: -100%;
        }
        #r4:checked ~ .s1 {
            margin-left: -300%;
        }
    }
    .card{
        margin: 0 calcFluid(8, 200, 320, 1920) 0 0;

    }
}
.advantages-row{
    padding:0 calcFluid(8, 300, 320, 1920);
    @extend %d-row;
}
.advantages{
    margin: 20px 0 0 0;
    box-shadow: 7px 7px 20px rgba(0, 0, 0, 0.25);
    background-color: white;
    @extend %d-col;
    padding: 10px;
    &__content{
        @extend %d-col;
        h2{
            font-family: "inter-bold";
            font-size: calcFluid(16, 20, 320, 1920);
            margin:calcFluid(10, 30, 320, 1920) 0 10px 0;
        }
        p{
            font-size: calcFluid(13, 16, 320, 1920);
            margin: 0;
    
        }
    }
    img{
        max-width: 27%;
    }
    &:not(:first-child){
        margin-left: calcFluid(15, 20, 1024, 1920);
    }
}
.card {
    @extend %card;
}
.choose{
    padding-right: calcFluid(8, 200, 320, 1920);
    width: 60%;
    margin: 0 0 100px 0;
    @extend %d-col;
  
    .choose__fd-col{
        position: relative; 
        display: flex;
        flex-direction: column;
        margin: 20px 0 auto auto;
        .choose__more{
            z-index: 5;
            @extend %more;  
            margin: 0;
        }
        .select{
            position: absolute;
            width: 100%;
            z-index: 100;
            top: 50px;
            background-color: white;
            @extend %d-col;
            box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.25);
            p{  
               padding: 5px ;
               margin: 0;
                box-sizing: border-box;
                -webkit-text-fill-color: black;
                &:hover{
                    background-color: #e7e7e7;
                }
            }
        }
    }     

    img{
        margin:50px 0 0 calcFluid(8, 200, 320, 1920);
        width: 75%;
    }
}
.content{
    display: flex;
    width: 40%;
    margin: auto 0;
    @extend %d-col;
    padding-left: calcFluid(8, 200, 320, 1920);
    select:focus,
    select:hover,
    option:hover,
    option:focus {
        border:none;
        outline:none;
        border:transparent;
    }
    .choose__more{
        display: none;
        @extend %more;
        option{
            color: black;
        }
    }
    h3{
        color: #58585e;
        font-family: "inter-bold";
        font-size:calcFluid(18, 25, 320, 1920);
        margin: 0;
    }
    h2{
        font-family: "inter-bold";
        font-size: calcFluid(13, 30, 320, 1920);
    }
    p{
        font-size: calcFluid(13, 20, 850, 1920);
        margin: 0 0 20px 0 ;
    }
    .fd-row{
        @extend %d-row;
        a{
            text-decoration: none;
            .content__text{
                font-size: calcFluid(12, 20, 320, 1920);
                font-family: "inter-bold";
                margin: 10px 0;
                color: black;
            }
        }
        .content__Youtube{
            margin: auto 10px auto 0;
            padding: 10px;
            border: 1px solid;
            border-image-source:linear-gradient(90deg, #5134c4,#773cbc,#b856d4);
            border-image-slice:1;
            .block1{
                border: calcFluid(5, 7, 320, 800)solid transparent;
                border-left: calcFluid(11, 13, 320, 800) solid;
                margin: auto 0 auto calcFluid(5, 8, 320, 800);
            }
        }
        .prev__active,
        .next__active{
            @extend %sliderBtn;
        }
        
        .prevFurniture, 
        .nextFurniture{
            border: 1.5px solid;
            font-family: "inter-bold";
            color: gray;
            padding: 0px 5px 5px 5px;
            font-size: calcFluid(25, 30, 1024, 1920);
            margin: 20px 0 0 10px;
        }
        .prev{
            margin: 20px 0 0 0;
        }
    }
}
.furniture {
    padding:  calcFluid(8, 20, 320, 1920) 40px  calcFluid(8, 20, 320, 1920) calcFluid(8, 200, 320, 1920) ;
    background-color: #e7e7e7;
    @extend %d-row;
    margin-right: calcFluid(8, 200, 320, 1920);
    .content{
        margin: auto 0;
        padding: 0;
        width: 35%;
        p{
            display: block;
        }
    }
    .card {
        box-shadow: 3px 1px 10px rgba(0, 0, 0, 0.25);
        .card__img{
            width: 60%;
            margin:  auto auto 0 auto ;
        }
        .card__bag{
            position: absolute; 
            bottom: -18px;
            right: 10px;
            border-radius: 50%;
            background: none;
            padding: calcFluid(1, 5, 320, 1920) calcFluid(6, 10, 320, 1920);
            border:2px solid #5134c4;
            font-size:20px;
        }
    }
    .slider{
        padding: 20px 0 20px 0;
        @extend %slider;
        width: 65%;
    }
}

.katalog{
    padding: 0  calcFluid(8, 200, 320, 1920);
    @extend %d-col;
    .katalog-row{
        @extend %d-row;
        margin: 20px 0 0 0;
        h2{
            font-family: "inter-bold";
            font-size:  calcFluid(17, 30, 320, 1920);
            margin:auto 0;
        }
        .prev,
        .next{
            cursor: pointer;
            background: #cacaca;
            color: #ffffff;
            border: none;
            font-family: "inter-bold";
            padding: 0px 7px 7px 7px;
            font-size:  calcFluid(20, 30, 320, 1920);
            margin: auto 0 auto 20px;
        }
        .prev{
            margin: auto 0 auto auto;
        }
        
        .next__active,
        .prev__active{
            background:$gradient;
        }
    }
    .slider{
        @extend %slider;
        padding: 20px 0;
        margin:0  calcFluid(0, 162, 850, 1920);
    }
    .card{
        margin: 0 calcFluid(10, 40, 320, 1920) 0 0;
    }
    .more{
        @extend %more;
        margin:20px auto;
    }
}


.mobile__katalog{
    padding: 0  calcFluid(1, 200, 320, 1920);
    display: flex;
    flex-direction: column;
    h2{
        font-size: calcFluid(21, 25, 320, 630);
    }
    .mobile__katalogSwipeContainer{
            overflow: scroll;
            position: relative;
            display: flex;
            flex-direction: row;
        .mobile__katalogSwipe{
            width: 1800px;
            display: flex;
        }
    }
    
    .mobile__katalogContainer{
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
    }
    .card{
        margin: 3px;
        width: calcFluid(143, 255, 320, 630);
    }
    .more{
        @extend %more;
        margin:20px auto;
        padding: 10px calcFluid(15, 20, 320, 800);
    }
}

@media(max-width: 1240px){
    .fd-row{
        .card {display: none};
        .slider{
            width: 100%;
            .slide{
                .slide__radius{
                    width:calcFluid(190, 350, 320, 1024);
                    height: calcFluid(130, 200, 320, 1024);
                    margin: auto 0 auto calcFluid(10, 300, 320, 1240);
                }
            }
        }
    }
    .advantages-row{
        padding: 0 calcFluid(8, 91, 320, 1024);
    }
}

@media(max-width: 850px){
    .advantages-row{
        display: flex;
        flex-direction: column;
    }
    .advantages{
        display: flex;
        flex-direction: row;
        &:not(:first-child){
            margin: 15px 0 0 0 ;
        }
        &__content{
            margin: auto 0 auto calcFluid(13, 20, 320, 800);
            h2{
                margin:0 0 7px 0 ;
            }
        }
    }
    .choose{
        margin: 0;
        .choose__more{
            display: none;
        }
        .choose__img{
            margin: 0;
        }
        img{
            width: 100%;
            margin: 0;
        }
    }
    .content{
        margin: auto 0;
        .choose__more{
            -webkit-appearance: none;
            display: block;
            margin: auto 30px auto 0;
        }
        h3{margin: 40px 0 0 0;}
        p{display: none;}
        .fd-row{.content__text{display: block;}}
        
    }
    .card {
        .card__sale {
            .fd-col{
                display: flex;
                flex-direction: row-reverse;
                margin: 10px 0 0 0;
                .card__price{
                    margin: 0 10px 0 0;
                }
                .discount{
                    margin: auto 0;
                }
            }
        }
    }
    .katalog{
        .card{
            width: calcFluid(132, 300, 320, 850) !important;
        }
    }
    .furniture {
        margin-top: 40px;
        .slider{
            .card{
                .card__img{
                    max-width: 90%;
                }
            }
            .furniture-line{
                width: 300%;
            }
        }

    }
    .katalog{ 
        .slider{
            margin: 0 calcFluid(0, 30, 320, 850);
            .katalog-line{
                width: 300%;
            }
        }
    }
}

@media(max-width: 630px){
    main{
        .mobile__katalog{
            .containerLoadingUser{
                .cardLoadingUser{
                    width: calcFluid(126, 247, 320, 630);
                }
            }
        }
    }
}


